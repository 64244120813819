import FirebaseUsage from "../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../firebase/constants";
import checkRelationValidity from "./checkLinkValidity";
import TaskStatusModel from "../../../../models/responses/task-status.model";
import {CpmTaskModel} from "../../../../models/responses/cpm-task.model";
import {TaskListSectionModel} from "../../../../models/task-list-section.model";
import batchUpdate from "../../../firestoreBatch.utils";

export default async function generateUploadData(
    projectId: string,
    db,
    initialRun: boolean,
    calendarsMap: Map<any, any>,
    tasks: Map<any, any>
) {
    const timeStart = new Date().getTime() / 1000
    const dataDate = Math.floor(timeStart / 86400) * 86400
    let tasksMap = new Map()
    const masterCalendarDict = (seconds, calendarId) => {
        return parseInt(calendarsMap.get(`mcd:${seconds}:${calendarId}`))
    }

    const masterWorkPatternDict = (index, calendarId) => {
        return parseInt(calendarsMap.get(`mwp:${index}:${calendarId}`))
    }

    function convertDateToIndex(date, calendarId) {
        let index = masterCalendarDict(date, calendarId)
        if (isNaN(index)) {
            return convertOutOfRangeDateToIndex(date, calendarId)
        }
        return index
    }

    function convertOutOfRangeDateToIndex(date, calendarId) {
        const maxDate = parseInt(calendarsMap.get(`swp:maxDate:${calendarId}`))
        const maxIndex = parseInt(calendarsMap.get(`swp:maxIndex:${calendarId}`))
        const halfHours = parseInt(calendarsMap.get(`swp:wi:count:${calendarId}`))
        const weeks = Math.floor((date - maxDate) / 604800)
        const maxWeekIndex = parseInt(calendarsMap.get(`swp:maxWeekIndex:${calendarId}`))
        const relativeMaxWeekIndex = masterWorkPatternDict(maxWeekIndex, calendarId)
        const remainingHalfHours = Math.floor((date - maxDate) / 1800) - (weeks * 336)
        const newMasterIndex = remainingHalfHours + maxWeekIndex > 336 ? (remainingHalfHours + maxWeekIndex) - 336 : remainingHalfHours + maxWeekIndex
        const newCalendarSpecificIndex = masterWorkPatternDict(newMasterIndex, calendarId)

        return remainingHalfHours + maxWeekIndex > 336 ? maxIndex + (weeks * halfHours) + (halfHours - relativeMaxWeekIndex) + (newCalendarSpecificIndex) :
            maxIndex + (weeks * halfHours) + (newCalendarSpecificIndex - relativeMaxWeekIndex)
    }


    const handleConstraint = {
        'CS_MSOA': ['s', 'y', 'n'],
        'CS_MEO': ['f', 'y', 'y'],
        'CS_MSO': ['s', 'y', 'y'],
        'CS_MEOA': ['f', 'y', 'n'],
        'CS_MEOB': ['f', 'n', 'y'],
        'CS_MSOB': ['s', 'n', 'y'],
        'CS_MANDFIN': ['f', 'y', 'y'],
        'CS_MANDSTART': ['s', 'y', 'y'],
        'CS_ALAP': ['f', 'n', 'n']
    }

    const handleTypes = {
        'TT_LOE': 0,
        'TT_FinMile': 1,
        'TT_Mile': 0,
        'TT_Task': 0,
        'TT_Rsrc': 0,
        'TT_WBS': 0,
        'TT_TASK': 0,
        'TT_RSRC': 0,
        'TT_MILE': 0,
        'TT_FINMILE': 1,
    }

    const handleWorkingTypes = {
        'TT_LOE': 0,
        'TT_FinMile': 1,
        'TT_Mile': 1,
        'TT_Task': 1,
        'TT_Rsrc': 1,
        'TT_WBS': 0,
        'TT_TASK': 1,
        'TT_RSRC': 1,
        'TT_MILE': 1,
        'TT_FINMILE': 1,
    }

    function getActualStart(task) {
        if (!task.act_start_date) {
            return ''
        } else {
            const actualStart = new Date(task.act_start_date.seconds * 1000)
            return convertDateToIndex(Math.floor((actualStart.getTime() / 1000) / 1800) * 1800, task.calendar_id)
        }
    }

    function getActualEnd(task) {
        if (!task.act_end_date && !task.declaredCompleteTimestamp) {
            return ''
        } else {
            const endDate = task.act_end_date ? task.act_end_date.seconds : task.declaredCompleteTimestamp.seconds
            const actualEnd = new Date(endDate * 1000)
            const endOffset = endDate !== task.act_start_date.seconds ? 1800 * 1000 : 0
            return convertDateToIndex(Math.floor(((actualEnd.getTime() - endOffset) / 1000) / 1800) * 1800, task.calendar_id)
        }
    }

    function lookForStartConstraints(task) {
        if (task.constraint_type === '') {
            return convertDateToIndex(dataDate, task.calendar_id)
        } else {
            const recalcDate = new Date(dataDate * 1000)
            const cstr = handleConstraint[task.constraint_type]
            if (cstr[1] === 'y') {
                if (cstr[0] === 's') {
                    const cstrDate = task.constraint_date.toDate()
                    return Math.max(convertDateToIndex(Math.floor(cstrDate.getTime() / 1800000) * 1800, task.calendar_id),
                        convertDateToIndex(Math.floor(recalcDate.getTime() / 1800000) * 1800, task.calendar_id))
                } else {
                    const cstrDate = task.constraint_date.toDate()
                    return Math.max(convertDateToIndex(Math.floor(cstrDate.getTime()/ 1800000) * 1800, task.calendar_id) -
                        Math.round(parseFloat(task.remainingDuration) * 2),
                        convertDateToIndex(Math.floor(recalcDate.getTime() / 1800000) * 1800, task.calendar_id))
                }
            } else {
                return convertDateToIndex(Math.floor(recalcDate.getTime() / 1800000) * 1800, task.calendar_id)
            }
        }
    }

    function lookForFinishConstraints(task) {
        if (task.constraint_type === '') {
            return ''
        } else {
            const cstr = handleConstraint[task.constraint_type]
            if (cstr[2] === 'y') {
                const cstrDate = task.constraint_date.toDate()
                if (cstr[0] === 'f') {
                    return convertDateToIndex(Math.floor(cstrDate.getTime() / 1800000) * 1800, task.calendar_id) - 1
                } else {
                    return convertDateToIndex(Math.floor(cstrDate.getTime() / 1800000) * 1800, task.calendar_id) -
                        Math.round(parseFloat(task.remainingDuration) * 2) - 1
                }
            } else {
                return ''
            }
        }
    }

    // get tasks from firestore
    let start: any[] = []
    let end: any[] = []
    let allTasks: any[] = []
    const taskList = async (projId) => {
        const calendarData: any = await FirebaseUsage.getQuery(COLLECTIONS.CALENDAR, ['project_id', '==', projId])
            .then(resp => resp.docs.map(doc => ({ ...doc.data(), calendarId: doc.id })) as any)
        const calendarDict = {}
        calendarData.forEach(calendar => {
            calendarDict[calendar.calendar_id] = calendar
        })

        let batch: any[] = []
        tasks.forEach((taskData, taskId) => {
            const task = {...taskData, calendar_id: calendarDict[taskData.calendar_id].calendarId}
            if ((task.status === TaskStatusModel.DECLARED_COMPLETE || task.status === TaskStatusModel.COMPLETE) &&
                !task.actualDuration && task.actualDuration !== 0) {
                const actualDuration = task.status === TaskStatusModel.DECLARED_COMPLETE ? Math.max(0,
                        convertDateToIndex(task.declaredCompleteTimestamp.seconds, task.calendar_id) - convertDateToIndex(task.act_start_date.seconds, task.calendar_id)) :
                    Math.max(0, convertDateToIndex(task.act_end_date.seconds, task.calendar_id) - convertDateToIndex(task.act_start_date.seconds, task.calendar_id))

                batch.push({
                    type: 'update',
                    ref: FirebaseUsage.getDocumentRef(COLLECTIONS.TASKS, taskId),
                    data: {actualDuration}
                })
            }

            let preds: any[] = []
            let succs: any[] = []
            let p_cnt = 0
            let s_cnt = 0

            task.relations.forEach(relation => {
                if (checkRelationValidity(relation.task_id === taskId ? taskData : tasks.get(relation.task_id),
                    relation.pred_task_id === taskId ? taskData : tasks.get(relation.pred_task_id),
                    relation)) {
                    const relationId = `${relation.pred_task_id}:${relation.task_id}:${relation.pred_type}`
                    tasksMap.set(relationId, {
                        task_id: relation.task_id,
                        link_id: relationId,
                        pred_task_id: relation.pred_task_id,
                        pred_type: relation.pred_type,
                        lag_hr_cnt: relation.lag,
                        ad: '',
                        af: '',
                    })
                    if (relation.task_id === taskId) {
                        preds.push(relationId)
                        p_cnt += 1
                    } else {
                        succs.push(relationId)
                        s_cnt += 1
                    }
                }
                else {
                    const relationId = `${relation.pred_task_id}:${relation.task_id}:${relation.pred_type}`
                    tasksMap.set(relationId, {
                        task_id: relation.task_id,
                        link_id: relationId,
                        pred_task_id: relation.pred_task_id,
                        pred_type: relation.pred_type,
                        lag_hr_cnt: relation.lag,
                        ad: '',
                        af: '',
                    })
                    if (relation.task_id === taskId) {
                        preds.push(relationId)
                        // p_cnt += 1
                    } else {
                        // succs.push(relationId)
                        s_cnt += 1
                    }
                }
            })
            tasksMap.set(`${taskId}:preds`, preds)
            tasksMap.set(`${taskId}:succs`, succs)

            // if (preds.length !== 0) {
            //     console.log(preds)
            //     console.log(tasksMap.get(`${taskId}:preds`))
            // }

            if (p_cnt === 0) {
                // && taskData.status !== TaskStatusModel.COMPLETE && taskData.status !== TaskStatusModel.DECLARED_COMPLETE) {
                start.push(taskId)
            }
            if (s_cnt === 0) {
                // && taskData.status !== TaskStatusModel.COMPLETE && taskData.status !== TaskStatusModel.DECLARED_COMPLETE) {
                end.push(taskId)
            }

            let cpmTask: CpmTaskModel = {
                task_code: taskId,
                es: getActualStart(task),
                ef: getActualEnd(task),
                ls: '',
                lf: '',
                ad: lookForStartConstraints(task) - handleTypes[task.task_type],
                af: lookForFinishConstraints(task),
                alap: '',
                p6_id: task.task_code,
                task_type: task.task_type,
                status_code: task.status,
                cal_id: task.calendar_id,
                duration: task.task_type !== "TT_LOE" ? parseInt(task.remainingDuration) : 0,
                di: 1,
                p_cnt: p_cnt,
                s_cnt: s_cnt,
                ec: task.constraint_type !== "" ? handleConstraint[task.constraint_type][2] === 'y' ? lookForFinishConstraints(task) : 'n' : 'n',
                cstr_type: task.constraint_type,
                p_sv: p_cnt,
                s_sv: s_cnt,
                task_name: `${task.task_code} - ${task.task_name}`,
                wbs: task.wbs,
                pi: task.predIndex,
                doc_ref: task.docRef,
                predStatus: task.predStatus,
            }

            if (task.overdue) {
                cpmTask.overdue = task.overdue
            }

            if (task.taskListType === TaskListSectionModel.PENDING) {
                cpmTask.pending = true
            }

            if (cpmTask.status_code === TaskStatusModel.DECLARED_COMPLETE || cpmTask.status_code === TaskStatusModel.COMPLETE) {
                cpmTask.duration = 0
            } else if (cpmTask.status_code === TaskStatusModel.IN_PROGRESS || cpmTask.status_code === TaskStatusModel.SUSPENDED) {
                if (cpmTask.es === '') {
                    cpmTask.es = cpmTask.ad
                }
                if (!initialRun) {
                    cpmTask.duration = Math.max(0,
                        convertDateToIndex(
                            Math.floor(task.expiryDate.seconds / 1800) * 1800, task.calendar_id) -
                    convertDateToIndex(dataDate, task.calendar_id))
                }
            }

            if (cpmTask.es === '') {
                cpmTask.es = cpmTask.ad
                // @ts-ignore
                cpmTask.ef = cpmTask.duration > 0 ? cpmTask.ad + cpmTask.duration - 1 : cpmTask.ad
            }
            if (cpmTask.ef === '') {
                // @ts-ignore
                cpmTask.ef = cpmTask.duration > 0 ? cpmTask.ad + cpmTask.duration - 1 : cpmTask.ad
            }

            if (cpmTask.status_code !== 'not started') {
                //@ts-ignore
                cpmTask.di = task.targetDuration ? task.targetDuration / Math.max(cpmTask.ef - cpmTask.es, 1) : 1
            }

            tasksMap.set(task.task_id, cpmTask)
            if (handleWorkingTypes[task.task_type] === 1) {
                allTasks.push(task.task_id)
            }
        })

        if (batch.length > 0) {
            await batchUpdate(batch)
        }
    }

    await taskList(projectId)
    tasksMap.set(`start:${projectId}`, start)
    tasksMap.set(`end:${projectId}`, end)

    return {tasksMap: tasksMap, allTasks: allTasks}
}