import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import { HeaderActionsButtonsWrapUI, HeaderWrapUI } from "./ui/HeaderUI";
import {
  Header,
  Loader,
} from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
  useActiveProjectCalendarsSelector,
  useActiveProjectSelector, useCpmCompleteSelector, useCpmMapSelector,
  useProjectCalculationsInProcessSelector,
  useProjectListSelector, useProjectViewSelector, useTrackedMilestonesSelector,
} from "../../../../store/selectors/project.selectors";
import {
  useAllTaskListSelector,
  useCompletedTasksAsPercentSelector,
} from "../../../../store/selectors/task/task.selectors";
import classNames from "classnames";
import { FlowChart } from "../FlowChart/FlowChart";
import moment from "moment";
import {useDispatch} from "react-redux";
import TrackedMilestoneModel from "../../../../models/responses/tracked-milestone.model";
import {convertDateToIndex, convertIndexToSeconds} from "../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../firebase/constants";
import {Actions} from "../../../../store/actions/project.actions";
import * as userActions from "../../../../store/actions/authorization.actions";
import "react-datepicker/dist/react-datepicker.css";
import ZoomI from "../DagChart/DagChartZoom";
import ManageMilestoneModal from "./components/ManageMilestoneModal";
import ProgressBar from "./components/ProgressBar";
import TaskPickerModal from "./components/TaskPickerModal";
import {useUserSelector} from "../../../../store/selectors/authorization.selectors";
import UserImage from "../../../../images/UserImage";
import TaskStatusModel from "../../../../models/responses/task-status.model";
import TaskModel from "../../../../models/responses/task.model";
import SprintChart from "../SprintChart/SprintChart";

interface InjectedProps {
  openSpecificAdditional: (
    type: "ledger" | "member" | "profile" | "calendar", open: boolean
  ) => void;
  isOpenedLedger: boolean;
  isOpenedMember: boolean;
  isOpenedProfile: boolean;
  isOpenedCalendar: boolean;
  isLarge: boolean;
  setLargeMode: Dispatch<SetStateAction<boolean>>;
  logout: (
    evt: any
  ) => void | undefined;
  setRequestModalOpen: (value: boolean) => void;
}

const HeaderContainer: React.FC<InjectedProps> = (props) => {
  const projects = useProjectListSelector();
  const activeProject = useActiveProjectSelector();
  const completedTasks = useCompletedTasksAsPercentSelector();
  const projectView = useProjectViewSelector();
  const [flowChartExpanded, setFlowChartExpanded] = useState(false);
  const [greenMilestoneExpanded, setGreenMilestoneExpanded] = useState(false);
  const [activeGreenMilestone, setActiveGreenMilestone] = useState(null);
  const [redMilestoneExpanded, setRedMilestoneExpanded] = useState(false);
  const [activeRedMilestone, setActiveRedMilestone] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showTaskPicker, setShowTaskPicker] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const isCalculationsModalOpened = useProjectCalculationsInProcessSelector();
  const [suspendedTasks, setSuspendedTasks] = useState(0);
  const [blockedTasks, setBlockedTasks] = useState(0);
  const [trackedMilestone, setTrackedMilestone] = useState<any | null>(null);
  const [trackedMilestoneVisibility, setTrackedMilestoneVisibility] = useState<any | null>("everyone");
  const dispatch = useDispatch();
  const trackedMilestones: TrackedMilestoneModel[] = useTrackedMilestonesSelector();
  const cpmMap = useCpmMapSelector()
  const cpmStatus = useCpmCompleteSelector()
  const projectCalendars = useActiveProjectCalendarsSelector()
  const [manageMilestones, setManageMilestones] = useState(false)
  const [showDag, setShowDag]: any = useState(false)
  const user = useUserSelector()

  // const testTask = cpmMap.get("iOKRWlR8VF3kvo6RVjZM")
  // console.log(new Date(convertIndexToSeconds(testTask.ls, testTask.cal_id, projectCalendars) * 1000))

  const constraintNames = {
    CS_MSO: "Must Start On",
    CS_MEO: "Must End On",
    CS_MSOB: "Start On or Before",
    CS_MEOB: "End On or Before",
    CS_MSOA: "Start On or After",
    CS_MEOA: "End On or After",
    CS_MANDFIN: "Mandatory Finish On",
    CS_MANDSTART: "Mandatory Start On",
    CS_MANADD: "Tracked Milestone"
  }
  const maxDate = activeProject ? activeProject.customViewFinish ? moment(new Date(activeProject.customViewFinish.toDate())).endOf('month') :
      moment(new Date(activeProject.latestDate)).endOf('month') : moment();
  const minDate = activeProject ? activeProject.customViewStart ? moment(new Date(activeProject.customViewStart.toDate())).startOf('month') :
      moment(new Date(activeProject.earliestDate)).startOf('month') : moment();

  const today = moment();
  const formatCriticalInTimeDifference = (days) => {
    if (days <= 0) {
      return "0 days";
    } else if (days === 1) {
      return "1 day";
    } else if (days <= 7) {
      return `${days} days`;
    } else if (days <= 30) {
      const weeks = Math.floor(days / 7);
      return `${weeks} week${weeks > 1 ? "s" : ""}`;
    } else {
      const months = Math.floor(days / 30);
      return `${months} month${months > 1 ? "s" : ""}`;
    }
  };

  const allTasks = useAllTaskListSelector();
  // const incompleteTasks = useMemo(() =>
  //     allTasks.filter((task) => task.status !== TaskStatusModel.COMPLETE &&
  //         task.status !== TaskStatusModel.DECLARED_COMPLETE)
  //         .map((el: TaskModel) => ({
  //           text: `${el.task_code} - ${el.task_name}`,
  //           key: el.task_id,
  //           value: el.task_id,
  //             }))
  //     , [allTasks]
  // )

  const greenMilestoneTasks: any = [];
  const redMilestoneTasks: any = [];
  if (cpmStatus) {
    trackedMilestones.forEach((el: any) => {
      // console.log(cpmMap)
      const task = cpmMap.get(el.taskId)
      const workingHours = projectCalendars.get(`whd:${task?.cal_id}`) || 8
      if (!task) {
        console.log("Tracked Milestone not found:", el.milestoneId)
        return
      }
      let liveEndDate = moment(new Date(convertIndexToSeconds(task.ef, task.cal_id, projectCalendars) * 1000))
      // let liveEndDate = moment()
      if (!liveEndDate) {
        liveEndDate = moment(el.baselineDate.toDate())
      }
      el.progressPosition = liveEndDate.diff(minDate, "days") / maxDate.diff(minDate, "days")
      el.key = el.milestoneId
      el.value = el.taskId
      el.text = `${el.taskCode} - ${el.milestoneName}`
      const delta = Math.abs(Math.ceil((convertDateToIndex(Math.ceil(el.baselineDate.seconds / 1800) * 1800, task.cal_id, projectCalendars) - task.ef) / (workingHours * 2)))
      el.diff = `${delta} day${delta > 1 ? "s" : ""}`
      el.early_end_date = liveEndDate.toDate().getTime()
      if (!liveEndDate.isBefore(moment())) {
        if (liveEndDate.isBefore(moment(el.baselineDate.toDate())) || liveEndDate.isSame(moment(el.baselineDate.toDate()))) {
          greenMilestoneTasks.push(el)
        } else {
          redMilestoneTasks.push(el)
        }
      }
    })
  }

  const progressPosition =
    (today.diff(minDate, "days") / maxDate.diff(minDate, "days")) * 100;

  useEffect(() => {
    if (!activeProject) {
      setFlowChartExpanded(false);
    }
  }, [activeProject]);

  useEffect(() => {
    let suspended = 0;
    let blocked = 0;
    for (let index = 0; index < projects.length; index++) {
      const project = projects[index];
      suspended += Object.keys(project.suspended).length;
      blocked += Object.keys(project.blocked).length;
    }
    setSuspendedTasks(suspended);
    setBlockedTasks(blocked);
  }, []);

  const openExternalHelpPage = () => {
    const externalURL = "https://www.flowledger.io/help";
    window.open(externalURL, "_blank");
  };

  const setMilestoneVisibility = async (milestone: TrackedMilestoneModel) => {
    FirebaseUsage.updateDoc(COLLECTIONS.TRACKED_MILESTONES, milestone.milestoneId, {hidden: !milestone.hidden}).catch(err => console.log(err))
    dispatch(Actions.setTrackedMilestones(trackedMilestones.map(currentState =>
      currentState.milestoneId === milestone.milestoneId ? ({...currentState, hidden: !currentState.hidden}) : currentState
    )))
  }

  const screenWidth = window.innerWidth;

  return (
    <React.Fragment>
      {showTaskPicker && <TaskPickerModal props={
          {
            showTaskPicker,
            setShowTaskPicker,
            incompleteTasks: allTasks.filter((task) => task.status !== TaskStatusModel.COMPLETE &&
                task.status !== TaskStatusModel.DECLARED_COMPLETE)
                .map((el: TaskModel) => ({
                  text: `${el.task_code} - ${el.task_name}`,
                  key: el.task_id,
                  value: el.task_id,
                })),
            activeProject,
            dispatch,
            setTrackedMilestone,
            setTrackedMilestoneVisibility,
            trackedMilestone,
            trackedMilestoneVisibility
          }
      } />}
      <HeaderWrapUI className="header-wrapper">
        <div className="header-menu-container">
          <div className="header-menu-content">
            {/*Burger Button*/}
            <div className="header-menu-button-wrap first">
              {suspendedTasks > 0 ? (
                <div
                  className={classNames("notifications-label", {
                    hide: props.isLarge,
                  })}
                >
                  {suspendedTasks}
                </div>
              ) : blockedTasks > 0 ? (
                <div
                  className={classNames("notifications-label", {
                    hide: props.isLarge,
                  })}
                >
                  {blockedTasks}
                </div>
              ) : null}
              <div
                className="header-menu-button"
                onClick={() => props.setLargeMode(!props.isLarge)}
              >
                <div
                  className={classNames("menu-burger", {
                    "times-mode": props.isLarge,
                  })}
                >
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            </div>
          </div>
          <Header as="h2" className="white-text m-0 flowledger-header-title">
            <div className="project-title">
              {activeProject && activeProject.name.includes(".xer")
                ? activeProject.name.substring(0, activeProject.name.length - 4)
                : activeProject && activeProject.name}
            </div>
          </Header>
        </div>
        <HeaderActionsButtonsWrapUI>
          <div className="button-profile" >
             {/*href="https://www.flowledger.io/s/FL-Quick-Start.pdf" target="_blank"*/}
            <FontAwesomeIcon
                style={{fontSize: "30px"}}
                // onClick={openExternalHelpPage}
                icon={faQuestion}
                onClick={() => props.setRequestModalOpen(true)}
            />
          </div>
          <div className="button-profile" onClick={() => dispatch(userActions.Actions.setUserSidebarOpen(true))}>
            {/*{user && user.photoURL ?*/}
            {/*    <img src={user.photoURL} alt="user" className="user-image"/>*/}
            {/*    : <div className="user-image-div"*/}
            {/*           style={{backgroundColor: user?.userColour ? user?.userColour : '#0d71bb'}}>{user && user.displayName ? user.displayName[0].toUpperCase() :*/}
            {/*        user?.userEmail[0].toUpperCase()}</div>}*/}
            <UserImage user={user!} width={40} height={40}/>
          </div>
          {/*<FontAwesomeIcon*/}
          {/*  style={{ fontSize: "30px" }}*/}
          {/*  onClick={props.logout}*/}
          {/*  icon={faSignOut}*/}
          {/*/>*/}
          {/*<Dropdown*/}
          {/*    pointing={"top right"}*/}
          {/*    icon={*/}
          {/*      <div className="button-profile">*/}
          {/*        {user && user.photoURL ?*/}
          {/*            <img src={user.photoURL} alt="user" className="user-image"/>*/}
          {/*            : <div className="user-image-div"*/}
          {/*                   style={{backgroundColor: user?.userColour ? user?.userColour : '#0d71bb'}}>{user && user.displayName ? user.displayName[0].toUpperCase() :*/}
          {/*                user?.userEmail[0].toUpperCase()}</div>}*/}
          {/*      </div>}>*/}
          {/*  <Dropdown.Menu className={"user-dropdown-menu"} style={{opacity: 1, zIndex: 300}}>*/}
          {/*    <Dropdown.Header content={`${user?.displayName || user?.userEmail}`}*/}
          {/*                     style={{fontSize: "14px", minWidth: "200px", textAlign: "center"}}/>*/}
          {/*    <MenuItem*/}
          {/*        disabled*/}
          {/*        onClick={() => console.log("my account clicked")}>*/}
          {/*      My Account*/}
          {/*    </MenuItem>*/}
          {/*    <MenuItem*/}
          {/*        onClick={(e) => {*/}
          {/*          props.logout(e)*/}
          {/*        }}*/}
          {/*    >*/}
          {/*      Sign Out*/}
          {/*    </MenuItem>*/}
          {/*  </Dropdown.Menu>*/}
          {/*</Dropdown>*/}
        </HeaderActionsButtonsWrapUI>
      </HeaderWrapUI>
      {/* the banner appear when calculations are working */}
      {activeProject ? (
          isCalculationsModalOpened ? (
              <h3
                  style={{
                    textAlign: "center",
                    margin: 0,
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                  }}
              >
                System updating <Loader active inline size="small"/>
              </h3>
          ) : null
      ) : null}
      <ProgressBar props={{
              progressPosition,
              completedTasks,
              activeProject,
              greenMilestoneTasks,
              redMilestoneTasks,
              showDropdown,
              setShowDropdown,
              setFlowChartExpanded,
              setShowDag,
              setGreenMilestoneExpanded,
              setRedMilestoneExpanded,
              setShowTaskPicker,
              setManageMilestones,
              setActiveGreenMilestone,
              setActiveRedMilestone,
              setMilestoneVisibility,
              setShowDatePicker,
              flowChartExpanded,
              activeGreenMilestone,
              activeRedMilestone
          }}
        />
      {flowChartExpanded && Boolean(activeProject) ? projectView === "sprint" ? (<SprintChart width={window.innerWidth} height={350} />) :
          (<FlowChart/>) : null}
      {/*<GoogleMapComponent/>*/}
      {/*<GoogleMapVisGL />*/}
      {/*{flowChartExpanded && Boolean(activeProject) && <FlowChart/>}*/}
      {showDag && (
          <ZoomI width={screenWidth}
                 height={400}
                 taskId={showDag === "green" ? greenMilestoneTasks.filter(milestone => milestone.milestoneId === activeGreenMilestone)[0].taskId :
                     redMilestoneTasks.filter(milestone => milestone.milestoneId === activeRedMilestone)[0].taskId}
                 delay={showDag === "red"}
                 scope={"trackedMilestones"}
                 setShowDag={setShowDag}
                 setActiveGreenMilestone={setActiveGreenMilestone}
                 setActiveRedMilestone={setActiveRedMilestone}
                 driving={true}
          />
      )}
      {greenMilestoneExpanded &&
        Boolean(activeProject) &&
        greenMilestoneTasks.map((task: any) => {
          if (task.key === activeGreenMilestone) {
            return (
              <div className="milestone green" key={task.key}>
                <div className="milestone-info">
                  <p className="name">{task.wbsName}&nbsp;-&nbsp;</p>
                  <p className="code">
                    {task.milestoneName}&nbsp;({task.taskCode})&nbsp;-&nbsp;{constraintNames[task.constraintType]}&nbsp;-
                    &nbsp;{moment(new Date(task.baselineDate.seconds * 1000)).format("DD-MMM-YYYY")}&nbsp;-
                    (current:&nbsp;{moment(new Date(task.early_end_date)).format("DD-MMM-YYYY")})
                  </p>
                </div>
                <div className="milestone-actions">
                  <p className="deley">on schedule</p>
                  <p className="driving-tasks"
                     onClick={() => {
                       setGreenMilestoneExpanded(false)
                       setShowDag("green")
                       setFlowChartExpanded(false)
                     }}>
                    Driving tasks</p>
                </div>
              </div>
            );
          }
        })}
      {redMilestoneExpanded &&
        Boolean(activeProject) &&
        redMilestoneTasks.map((task: any) => {
          if (task.key === activeRedMilestone) {
            return (
              <div className="milestone red" key={task.key}>
                <div className="milestone-info">
                  <p className="name">{task.wbsName}&nbsp;-&nbsp;</p>
                  <p className="code">
                    {task.milestoneName}&nbsp;({task.taskCode})&nbsp;-&nbsp;{constraintNames[task.constraintType]}&nbsp;-
                    &nbsp;{moment(new Date(task.baselineDate.seconds * 1000)).format("DD-MMM-YYYY")}&nbsp;-
                    (current:&nbsp;{moment(new Date(task.early_end_date)).format("DD-MMM-YYYY")})
                  </p>
                </div>
                <div className="milestone-actions">
                  <p className="deley">{task.diff}&nbsp;behind</p>
                  <p className="driving-tasks"
                     onClick={() => {
                       setRedMilestoneExpanded(false)
                       setShowDag("red")
                       setFlowChartExpanded(false)
                     }
                  }
                  >Driving tasks</p>
                </div>
              </div>
            );
          }
        })}
      <ManageMilestoneModal
        manageMilestones={manageMilestones}
        setManageMilestones={setManageMilestones}
        trackedMilestones={trackedMilestones}
        setMilestoneVisibility={setMilestoneVisibility}
      />
    </React.Fragment>
  );
};

export default HeaderContainer;
