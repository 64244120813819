import { CalculationsState } from '../../models/calculations-state.enum';
import { SUB_COLLECTIONS } from '../constants';
import FirebaseUsage from "../firebase.usage";
import {COLLECTIONS} from "../constants";
import ProjectModel from "../../models/responses/project.model";
import {DefaultSubscribeCallback} from "../../store/middleware/middlewares";
import { decompressFromBase64 } from 'lz-string'
import {CalendarModel} from "../../models/responses/calendar.model";
import { ClientDocModel } from '../../models/responses/client.model';
import {SprintDataModel} from "../../models/sprint.model";

export class ProjectSubscriptions {
    static async projectList(userId: string | null, callback: DefaultSubscribeCallback<ProjectModel[]>) {
      const client =  await FirebaseUsage.getQuery(COLLECTIONS.CLIENTS, ["domain", "==", window.location.hostname]).
        then((snapshot) => {
            if (snapshot.docs.length) {
              // hasUser = true;
              return snapshot.docs[0] ? snapshot.docs[0].data() as ClientDocModel : null;
            }
          })

        // const conditions = client ? [['uploadState', '==', CalculationsState.DONE],['clientId', '==', client.clientId]] : [['uploadState', '==', CalculationsState.DONE]]

        const conditions = [['uploadState', '==', CalculationsState.DONE]]

        return FirebaseUsage.queryListener(COLLECTIONS.PROJECTS, conditions,
        async (data: any) => {
                let projectData = await Promise.all(data.docs.map( async (doc: any) => {
                    // let test = doc.data();
                    // @ts-ignore
                    if(doc.data().isUpdate){
                        return null;
                    }
                    if (!doc.data().grade) {
                        return null;
                    } else if (!doc.data().grade[userId!]) {
                        return null;
                    }

                    let calendarsArr:any[] = [];
                    let debugDataStr = "";
                    let debugDataArr:any[] = []
                    let curvesStr = "";
                    let curvesArr:any[] = []
                    let calendarDocs = await FirebaseUsage.getSubCollection(COLLECTIONS.PROJECTS, doc.id, SUB_COLLECTIONS.CALENDARS);

                    calendarDocs.docs.map((doc) => {
                        calendarsArr.push(doc.data());
                        return null;
                    })
                    calendarsArr.sort((a,b) => a.order - b.order)
                    let calArray = calendarsArr.map( el => {
                        const calData = JSON.parse(el.calendars)
                        let calMap: Map<string, any> | null = null
                        if (calData.workday_exceptions) {
                            calMap = new Map()
                            calData.workday_exceptions.forEach((exception: any) => {
                                calMap?.set(exception.date, exception)
                            })
                        }
                        return {
                            ...calData, exceptionsArr: calMap
                        }
                    })

                    let calendarDict = {}
                    const getCalendars = await FirebaseUsage.getQuery(COLLECTIONS.CALENDAR, ['project_id', '==', doc.id])
                    .then(data => data.docs.map(el => ({...el.data() as CalendarModel, id: el.id})))
                    for (const cal of getCalendars) {
                        calendarDict[cal.calendar_id] = cal.id
                    }

                debugDataArr.sort((a,b) => a.order - b.order)
                for (const el of debugDataArr) {
                    debugDataStr =  debugDataStr.concat( el.debugdata)
                }

                let curvesDocs =  await FirebaseUsage.getSubCollection(COLLECTIONS.PROJECTS, doc.id, SUB_COLLECTIONS.CURVES);

                curvesDocs.docs.map((doc) => {
                    curvesArr.push(doc.data());
                    return null
                });

                curvesArr.sort((a,b) => a.order - b.order)
                for (const el of curvesArr) {
                    curvesStr =  curvesStr.concat( el.curves)
                }
                    const racingLine = doc.data().racingLine;
                    const curves = decompressFromBase64(curvesStr);
                    return {
                        ...doc.data(),
                        projectId: doc.id,
                        issueCategories: doc.data().issueCategories ? doc.data().issueCategories : [
                            {reason: 'Directive', text: 'Directive'},
                            {reason: 'Incident', text: 'Incident'},
                            {reason: 'Information', text: 'Information'},
                            {reason: 'Resource', text: 'Resource'},
                            {reason: 'Other', text: 'Other'},
                        ],
                        curves: curves ? JSON.parse(curves) : {
                            early: [],
                            late: [],
                            expected: [],
                            actual: [],
                        },
                        racingLine: typeof racingLine === 'string' ? JSON.parse(doc.data().racingLine) : racingLine,
                        calendars: calArray,
                        calendarDict:calendarDict,
                    } as ProjectModel
                    }))
                    let projectDataFilter = projectData.filter( e => e !== null) as Array<ProjectModel>;
                callback(projectDataFilter)
            });
    }

    static async trackedMilestones(projectId: string, callback: any) {
        return FirebaseUsage.getQuery(COLLECTIONS.TRACKED_MILESTONES, ['projectId', '==', projectId]).then(data => {
            callback(data.docs.map(doc => doc.data()))
        })
    }

    static async getGeoData(projectId: string, callback: any) {
        return FirebaseUsage.queryListener(COLLECTIONS.GEO_DATA, [['projectId', '==', projectId]], (data: any) => {
            callback(data.docs.map((doc: any) => doc.data()))
        })
    }

    static async getDataVersions(contractId: string, callback: any) {
        return FirebaseUsage.getQuery(COLLECTIONS.DATA_VERSIONS, ['contractsUpdated', 'array-contains', contractId])
            .then(data => callback(data.docs.map(doc => doc.data())))
    }

    static async getSprintData(contractId: string, callback: any) {
        return FirebaseUsage.getQuery(COLLECTIONS.SPRINT_DATA, ['contractId', '==', contractId])
            .then(data => callback(data.docs.map(doc => doc.data() as SprintDataModel)))
    }
}
