import React, {useCallback, useEffect, useState} from "react";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {TaskPredecessors} from "./TaskPredecessors";
import {TaskSuccessors} from "./TaskSuccessors";
import {TaskListSection} from "./TaskListSection/TaskListSection";
import {TaskListSectionModel} from "../../../../../models/task-list-section.model";
import InfiniteScroll from "react-infinite-scroller";
import {useDispatch} from "react-redux";
import * as taskActions from "../../../../../store/actions/task.actions";
import * as projectActions from "../../../../../store/actions/project.actions";
import {Dropdown, Loader} from "semantic-ui-react";
import {
    useActiveDataVersionSelector,
    useActiveProjectCalendarsSelector,
    useActiveProjectSelector,
    useCpmMapSelector,
    useDataVersionsSelector,
    useProjectViewSelector,
    useViewWidthSelector
} from "../../../../../store/selectors/project.selectors";
import {
    useIncludeMilestonesSelector,
    useSearchParamatersSelector
} from "../../../../../store/selectors/search.selectors";
import TaskModel from "../../../../../models/responses/task.model";
import GoogleMapVisGL from "../../../components/GoogleMap/GoogleMapVisGL";
import filterTaskList from "../../../../../utils/task-filtering";
import TaskStatusModel from "../../../../../models/responses/task-status.model";
import {
    useAllTaskListSelector,
    useNextSnapshotSelector,
    useTaskSnapshotSelector
} from "../../../../../store/selectors/task/task.selectors";
import {subsManager} from "../../../../../store/middleware/subs-manager/subs-manager";
import {TaskType} from "../../../../../models/task-type";
import {convertIndexToSeconds} from "../../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlagCheckered, faGun, faPersonRunningFast} from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import {SprintTaskType} from "../../../../../models/sprint.model";

export const SprintTaskList = () => {
    const [view, setView] = useState<"future" | "past">("future");
    const [sortCriteria, setSortCriteria] = useState<"index" | "deadline" | "target">("index");
    const activeProject = useActiveProjectSelector();
    const dispatch = useDispatch();
    const projectView = useProjectViewSelector();

    let now = new Date();
    now.setMinutes(0, 0, 0);

    const loading = useTypedSelector((state) => state.task.taskListLoading);
    const moreTasks = useTypedSelector((state) => state.task.moreTasks);
    const searchParams = useSearchParamatersSelector();
    const allTasks = useAllTaskListSelector();
    const includeMilestones = useIncludeMilestonesSelector();
    const dataVersions = useDataVersionsSelector();
    const viewWidth = useViewWidthSelector();
    // const [activeDataVersion, setActiveDataVersion] = useState<any>(dataVersions[0]);

    const [filteredArchivedTaskList, setFilteredArchivedTaskList] = useState<TaskModel[]>([]);
    const [filteredDeclaredTaskList, setFilteredDeclaredTaskList] = useState<TaskModel[]>([]);
    const [filteredInProgressTaskList, setFilteredInProgressTaskList] = useState<TaskModel[]>([]);
    const [filteredQueuedTaskList, setFilteredQueuedTaskList] = useState<TaskModel[]>([]);
    const [archivedOpen, setArchivedOpen] = useState(false);
    const [declaredOpen, setDeclaredOpen] = useState(false);
    const [inProgressOpen, setInProgressOpen] = useState(true);
    const [queuedOpen, setQueuedOpen] = useState(false);
    const [sprintPopup, setSprintPopup] = useState<any>(null);
    const [archivedTaskCount, setArchivedTaskCount] = useState(0);
    const [declaredTaskCount, setDeclaredTaskCount] = useState(0);
    const [inProgressTaskCount, setInProgressTaskCount] = useState(0);
    const [queuedTaskCount, setQueuedTaskCount] = useState(0);
    const [tasksDueToStartCount, setTasksDueToStartCount] = useState(0);
    const [tasksDueToFinishCount, setTasksDueToFinishCount] = useState(0);
    const [tasksStartedCount, setTasksStartedCount] = useState(0);
    const [tasksStartedAheadCount, setTasksStartedAheadCount] = useState(0);
    const [tasksFinishedAheadCount, setTasksFinishedAheadCount] = useState(0);
    const [allStartedTasksCount, setAllStartedTasksCount] = useState(0);
    const [tasksFinishedCount, setTasksFinishedCount] = useState(0);
    const [allFinishedTasksCount, setAllFinishedTasksCount] = useState(0);
    const [selectedSprintFilter, setSelectedSprintFilter] = useState<any>([]);
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const taskSnapshot = useTaskSnapshotSelector();
    const nextSnapshot = useNextSnapshotSelector();
    const projectCalendars = useActiveProjectCalendarsSelector();
    const cpmMap = useCpmMapSelector();
    const activeDataVersion = useActiveDataVersionSelector();
    const [usingTwoSnapshots, setUsingTwoSnapshots] = useState<any>(activeDataVersion?.index || null);

    useEffect(() => {
        if (taskSnapshot.size === 0) {
            subsManager.subscribeTaskSnapshot(activeDataVersion.version.dataVersionId || "", activeProject?.contractId || "");
            if (usingTwoSnapshots !== null) {
                console.log('other data version', dataVersions[usingTwoSnapshots].dataVersionId)
                subsManager.subscribeNextSnapshot(dataVersions[usingTwoSnapshots].dataVersionId, activeProject?.contractId || "");
            }
        }
    }, [taskSnapshot]);

    // 1500 min width for showing other information on tasks
    useEffect(() => {
        subsManager.subscribeSprintData(activeProject?.contractId || "");
    }, []);

    useEffect(() => {
        if (taskSnapshot.size > 0 && (usingTwoSnapshots === null || nextSnapshot.size > 0)) {
            const periodStart = new Date((Math.floor(activeDataVersion.version.dataVersionDate.seconds / 86400) * 86400) * 1000);
            const cutOffDate = new Date(periodStart.getTime() + (7 * 86400000));
            let archivedTaskList: TaskModel[] = [];
            let declaredTaskList: TaskModel[] = [];
            let inProgressTaskList: TaskModel[] = [];
            let archivedTaskCount = 0;
            let declaredTaskCount = 0;
            let inProgressTaskCount = 0;
            let tasksStartCount = 0;
            let tasksFinishCount = 0;
            let startedTasksCount = 0;
            let finishedTasksCount = 0;
            let totalStartedTasks = 0;
            let totalFinishedTasks = 0;
            let totalFinishedAhead = 0;
            let totalStartedAhead = 0;
            let queuedTaskList: TaskModel[] = [];
            let queuedTaskCount = 0;
            filterTaskList(allTasks, searchParams, cpmMap, projectCalendars)
                .filter((task: TaskModel) => {
                        const taskSnapshotData = taskSnapshot.get(task.task_code);
                        if (!taskSnapshotData) return false;
                        if (taskSnapshotData.forecastStartDate.toDate().getTime() >= cutOffDate.getTime()
                            && !task.act_end_date && !task.declaredCompleteTimestamp
                        ) return false;
                        if (taskSnapshotData.actStartDate && !taskSnapshotData.flow &&
                            !task.act_end_date && !task.declaredCompleteTimestamp) return false;
                        if (!includeMilestones && task.task_type !== TaskType.TT_RSRC && task.task_type !== TaskType.TT_TASK) return false;
                        return !taskSnapshotData.actEndDate;
                    }
                )
                .forEach((task: TaskModel) => {
                    let included = false;
                    const taskSnapshotData = taskSnapshot.get(task.task_code);
                    const nextSnapshotData = nextSnapshot.get(task.task_code);
                    const cpmTask = cpmMap.get(task.task_id);
                    if (taskSnapshotData && (usingTwoSnapshots === null || nextSnapshotData)) {
                        const taskActualEndDate = usingTwoSnapshots !== null ? nextSnapshotData!.actEndDate || task.act_end_date || task.declaredCompleteTimestamp : task.act_end_date || task.declaredCompleteTimestamp;
                        const taskActualStartDate = usingTwoSnapshots !== null ? nextSnapshotData!.actStartDate : task.act_start_date;

                        if (taskSnapshotData.forecastStartDate.toDate().getTime() > periodStart.getTime()
                            && taskSnapshotData.forecastStartDate.toDate().getTime() < cutOffDate.getTime()
                        ) {
                            if (!taskSnapshotData.actStartDate) {
                                tasksStartCount++;
                            }
                            // task.sprintCategory = SprintTaskType.DID_NOT_START

                            if (taskActualStartDate && !taskSnapshotData.actStartDate &&
                                taskActualStartDate.toDate().getTime() >= periodStart.getTime()) {
                                startedTasksCount++;
                                if (!selectedSprintFilter.length || selectedSprintFilter.includes('start')) {
                                    included = true;
                                }
                                // task.sprintCategory = SprintTaskType.STARTED
                            }
                        }
                        if (!taskSnapshotData.actStartDate && taskActualStartDate && taskActualStartDate.toDate().getTime() < periodStart.getTime()) {
                            totalStartedAhead++;
                            if (!selectedSprintFilter.length || selectedSprintFilter.includes('start-early')) {
                                included = true;
                            }
                        }
                        else if (taskActualStartDate && !taskSnapshotData.actStartDate) {
                            totalStartedTasks++;
                            if (!selectedSprintFilter.length || selectedSprintFilter.includes('start-unplanned')) {
                                included = !(task.sprint === activeDataVersion.version.dataVersionId);
                            }
                        }
                        // if (task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED) {
                            // if (task.progress && task.progress.filter(p => p.date.toDate().getTime() >= periodStart.getTime()).length) {
                            //     task.sprintCategory = SprintTaskType.PROGRESSED;
                            // } else {
                            //     task.sprintCategory = SprintTaskType.NO_PROGRESS;
                            // }
                        // }
                        if (taskSnapshotData.forecastEndDate.toDate().getTime() < cutOffDate.getTime()) {
                            tasksFinishCount++;
                            // task.sprintCategory = SprintTaskType.DID_NOT_FINISH
                            if (taskActualEndDate && taskActualEndDate.toDate().getTime() > periodStart.getTime()) {
                                finishedTasksCount++;
                                totalFinishedTasks++;
                                if (!selectedSprintFilter.length || selectedSprintFilter.includes('finish')) {
                                    included = true;
                                }
                                // task.sprintCategory = SprintTaskType.FINISHED
                            }
                        } else if (taskActualEndDate &&
                            taskActualEndDate.toDate().getTime() < cutOffDate.getTime() &&
                            taskActualEndDate.toDate().getTime() >= periodStart.getTime()) {
                            totalFinishedTasks++;
                            if (!selectedSprintFilter.length || selectedSprintFilter.includes('finish-unplanned')) {
                                included = !selectedSprintFilter.length || !included;
                            }
                        }
                        if (taskActualEndDate && taskActualEndDate.toDate().getTime() < periodStart.getTime()) {
                            totalFinishedAhead++;
                            if (!selectedSprintFilter.length || selectedSprintFilter.includes('finish-early')) {
                                included = true;
                            }
                            // if (task.sprintCategory === SprintTaskType.DID_NOT_FINISH) {
                                // task.sprintCategory = SprintTaskType.FINISHED
                            // }
                        }

                        if (selectedSprintFilter.length === 0) {
                            included = true;
                        }
                        if (!included) return;

                        // add tasks to the correct list
                        if (task.status === TaskStatusModel.COMPLETE) {
                            archivedTaskList.push(task);
                            archivedTaskCount++;
                        } else if (task.status === TaskStatusModel.DECLARED_COMPLETE) {
                            declaredTaskList.push(task);
                            declaredTaskCount++;
                        } else if (cpmTask.flow || task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED) {
                            inProgressTaskList.push(task);
                            inProgressTaskCount++;
                        } else {
                            queuedTaskList.push(task);
                            queuedTaskCount++;
                        }
                    }
                });
            if (sortCriteria === "index") {
                inProgressTaskList.sort((a, b) => {
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    if (!aTask || !bTask) return 0;
                    return aTask.index! - bTask.index!
                });
                queuedTaskList.sort((a, b) => {
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    if (!aTask || !bTask) return 0;
                    return aTask.index! - bTask.index!
                });
            } else if (sortCriteria === "deadline") {
                inProgressTaskList.sort((a, b) => {
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                    const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                    return aLateEndDate - bLateEndDate;
                });
                queuedTaskList.sort((a, b) => {
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                    const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                    return aLateEndDate - bLateEndDate;
                });
            } else if (sortCriteria === "target") {
                inProgressTaskList = inProgressTaskList.sort((a, b) => {
                    if (a.targetFinishDate) {
                        if (b.targetFinishDate) {
                            return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                        }
                        return -1;
                    }
                    if (b.targetFinishDate) {
                        return 1;
                    }
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                    const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                    return aEarly - bEarly;
                });
                queuedTaskList = queuedTaskList.sort((a, b) => {
                    if (a.targetFinishDate) {
                        if (b.targetFinishDate) {
                            return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                        }
                        return -1;
                    }
                    if (b.targetFinishDate) {
                        return 1;
                    }
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                    const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                    return aEarly - bEarly;
                });
            }

            // const allSprintTasks = [...archivedTaskList, ...declaredTaskList, ...inProgressTaskList, ...queuedTaskList];

            setFilteredArchivedTaskList(archivedTaskList);
            setFilteredDeclaredTaskList(declaredTaskList);
            setFilteredInProgressTaskList(inProgressTaskList);
            setFilteredQueuedTaskList(queuedTaskList);
            setArchivedTaskCount(archivedTaskCount);
            setDeclaredTaskCount(declaredTaskCount);
            setInProgressTaskCount(inProgressTaskCount);
            setQueuedTaskCount(queuedTaskCount);
            setTasksDueToStartCount(tasksStartCount);
            setTasksDueToFinishCount(tasksFinishCount);
            setTasksStartedCount(startedTasksCount);
            setTasksFinishedCount(finishedTasksCount);
            setAllFinishedTasksCount(totalFinishedTasks);
            setAllStartedTasksCount(totalStartedTasks);
            setTasksStartedAheadCount(totalStartedAhead);
            setTasksFinishedAheadCount(totalFinishedAhead);

            // if (!usingTwoSnapshots && usingTwoSnapshots !== 0) {
            //     setTasksStartedAheadCount(totalStartedAhead);
            //     setTasksFinishedAheadCount(totalFinishedAhead);
            // } else {
            //     setTasksStartedAheadCount(0);
            //     setTasksFinishedAheadCount(0);
            // }
            setFiltersLoaded(true);
        }
    }, [searchParams, sortCriteria, taskSnapshot, nextSnapshot, allTasks, selectedSprintFilter]);

    const handleChangedSnapshot = (version: any, index) => {
        if (version.dataVersionId === activeDataVersion?.version.dataVersionId) return;
        setFiltersLoaded(false);
        // setActiveDataVersion(version);
        dispatch(taskActions.Actions.setNextSnapshot(new Map()));
        dispatch(taskActions.Actions.setTaskSnapshot(new Map()));
        // subsManager.subscribeTaskSnapshot(version.dataVersionId, activeProject?.contractId || "");
        if (index !== 0) {
            // subsManager.subscribeNextSnapshot(dataVersions[index - 1].dataVersionId, activeProject?.contractId || "");
            setUsingTwoSnapshots(index - 1);
        } else {
            setUsingTwoSnapshots(null);
        }
        // setActiveDataVersion(version)
        dispatch(projectActions.Actions.setActiveDataVersion({version, index}));
    }

    const openPredecessors = useTypedSelector(
        (state) => state.task.predecessors.open
    );
    const openSuccessors = useTypedSelector(
        (state) => state.task.successors.open
    );
    const loadMoreHandler = useCallback(
        (page: number) => {
            if (view === "future") {
                if (!loading.QueuedIsLoading && moreTasks.queued) {
                    dispatch(
                        taskActions.Actions.loadMoreTaskList(
                            page,
                            TaskListSectionModel.QUEUED
                        )
                    );
                }
            } else {
                if (
                    !loading.ConfirmedCompleteIsLoading &&
                    moreTasks.confirmedComplete
                ) {
                    dispatch(
                        taskActions.Actions.loadMoreTaskList(
                            page,
                            TaskListSectionModel.CONFIRMED_COMPLETE
                        )
                    );
                }
            }
        },
        [
            dispatch,
            loading.ConfirmedCompleteIsLoading,
            loading.QueuedIsLoading,
            moreTasks.confirmedComplete,
            moreTasks.queued,
            view,
        ]
    );

    if (openPredecessors) {
        return <TaskPredecessors />;
    }

    if (openSuccessors) {
        return <TaskSuccessors />;
    }

    const totalStartCount = Math.max(
        tasksDueToStartCount + tasksStartedAheadCount + (allStartedTasksCount - tasksStartedCount),
        allStartedTasksCount + tasksStartedAheadCount
    );
    const totalFinishCount = Math.max(
        tasksDueToFinishCount + tasksFinishedAheadCount + (allFinishedTasksCount - tasksFinishedCount),
        allFinishedTasksCount + tasksFinishedAheadCount
    );

    const unplannedStartCount = allStartedTasksCount - tasksStartedCount;
    const unplannedFinishCount = allFinishedTasksCount - tasksFinishedCount;

    return (
        <div style={{minWidth: "100%"}}>
            <div className="sprint-summary-container">
                <div className="sprint-titles">
                    <div>Sprint:</div>
                    {dataVersions.length > 0 ?
                        <div style={{zIndex: 350}} className="sprint-dropdown-div">
                            <Dropdown
                                selection
                                fluid
                                scrolling
                                className={"sprint-date-dropdown"}
                                // icon={null}
                                text={activeDataVersion ?
                                    moment(activeDataVersion?.version.dataVersionDate.toDate()).format("MMM Do, YYYY") :
                                    moment(activeProject?.lastSnapshotTimestamp?.toDate()).format("MMM Do, YYYY")}
                                options={dataVersions.map((version, i) => ({
                                        key: version.dataVersionId,
                                        value: version.dataVersionId,
                                        text: 'P' + (dataVersions.length - i).toString() + ' | ' + moment(version.dataVersionDate.toDate()).format("MMM Do, YYYY"),
                                        onClick: () => handleChangedSnapshot(version, i)
                                    })
                                )}
                            />
                            {/*    <Dropdown.Menu>*/}
                            {/*        {dataVersions.map((version, i) => (*/}
                            {/*            <Dropdown.Item*/}
                            {/*                key={version.dataVersionId}*/}
                            {/*                onClick={() => {*/}
                            {/*                    handleChangedSnapshot(version, i);*/}
                            {/*                }}*/}
                            {/*                style={{fontSize: "12px"}}*/}
                            {/*                text={'P'+(dataVersions.length - i).toString() + ' | ' + moment(version.dataVersionDate.toDate()).format("MMM Do, YYYY")}*/}
                            {/*            >*/}
                            {/*                /!*{moment(version.dataVersionDate.toDate()).format("MMM Do, YYYY")}*!/*/}
                            {/*            </Dropdown.Item>*/}
                            {/*        ))}*/}
                            {/*    </Dropdown.Menu>*/}
                            {/*</Dropdown>*/}
                        </div> :
                        moment(activeProject?.lastSnapshotTimestamp?.toDate()).format("MMM Do, YYYY")}
                </div>
                <div style={{fontSize: "30px", marginTop: "-5px"}}>{' | '}</div>
                <FontAwesomeIcon icon={faPersonRunningFast} size={"2xl"} className="sprint-summary-icon"/>
                <span className="sprint-summary-figures">
                    <div className="sprint-summary-total">
                        Started:
                        <div className="sprint-total-div">
                            {tasksStartedAheadCount > 0 &&
                                <div className="sprint-progress-bar-finish-early"
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: false,
                                         planned: true,
                                         early: true,
                                         count: tasksStartedAheadCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('start-early') ? prevState.filter((el: string) =>
                                             el !== 'start-early') : [...prevState, 'start-early'])}
                                     style={{
                                         minWidth: `calc(${Math.min(100, tasksStartedAheadCount / totalStartCount) * 100}% - ${
                                             Math.min(100, tasksStartedAheadCount / totalStartCount * 59)}px)`,
                                     }}>
                                    <p className="progress-total-inside">{tasksStartedAheadCount}</p>
                                </div>}
                            {unplannedStartCount > 0 &&
                                <div className="sprint-progress-bar-start-unplanned"
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: false,
                                         planned: false,
                                         count: unplannedStartCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('start-unplanned') ? prevState.filter((el: string) =>
                                             el !== 'start-unplanned') : [...prevState, 'start-unplanned'])}
                                     style={{
                                         minWidth: `calc(${Math.min(100,
                                             (unplannedStartCount) / totalStartCount * 100)}% - ${
                                             Math.min(100, (unplannedStartCount) / totalStartCount * 59)}px)`
                                     }}>
                                    <p className="progress-total-inside">{unplannedStartCount}</p>
                                </div>}
                            {tasksStartedCount > 0 &&
                                <div className="sprint-progress-bar-start"
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: false,
                                         planned: true,
                                         count: tasksStartedCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                    onClick={() => setSelectedSprintFilter(prevState =>
                                        prevState.includes('start') ? prevState.filter((el: string) =>
                                            el !== 'start') : [...prevState, 'start'])}
                                     style={{
                                         minWidth: `calc(${Math.min(100, tasksStartedCount / totalStartCount * 100)}% - ${
                                             Math.min(100, tasksStartedCount / totalStartCount * 59)}px)`,
                                     }}>
                                    <p className="progress-total-inside">{tasksStartedCount}</p>
                                </div>}
                            {tasksStartedCount === 0 && allStartedTasksCount === 0 &&
                                <p className="progress-total-outside">0</p>}
                            <div className="finish-line">
                                <span>
                                    <p>{`${tasksDueToStartCount}|`}</p>
                                    <FontAwesomeIcon icon={faGun}
                                                     className="finish-line-icon"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="sprint-summary-total"
                         style={{
                             marginLeft: "-20px",
                             paddingRight: "15px"
                         }}
                    >
                        Completed:
                        <div className="sprint-total-div">
                            {tasksFinishedAheadCount > 0 &&
                                <div className="sprint-progress-bar-finish-early"
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: true,
                                         planned: true,
                                         early: true,
                                         count: tasksFinishedAheadCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                    onClick={() => setSelectedSprintFilter(prevState =>
                                        prevState.includes('finish-early') ? prevState.filter((el: string) =>
                                            el !== 'finish-early') : [...prevState, 'finish-early'])}
                                     style={{
                                         minWidth: `calc(
                                         ${Math.min(100, (tasksFinishedAheadCount / totalFinishCount) * 100)}% - ${
                                             Math.min(100, tasksFinishedAheadCount / totalFinishCount * 59)}px)`,
                                     }}>
                                    <p className="progress-total-inside">{tasksFinishedAheadCount}</p>
                                </div>}
                            {unplannedFinishCount > 0 &&
                                <div className="sprint-progress-bar-finish-unplanned"
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: true,
                                         planned: false,
                                         count: unplannedFinishCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                        prevState.includes('finish-unplanned') ? prevState.filter((el: string) =>
                                            el !== 'finish-unplanned') : [...prevState, 'finish-unplanned'])}
                                     style={{
                                         minWidth: `
                                         calc(
                                         ${Math.min(100, unplannedFinishCount / totalFinishCount * 100)}% - ${
                                             Math.min(100, unplannedFinishCount / totalFinishCount * 59)}px)`,
                                     }}>
                                    <p className="progress-total-inside">{unplannedFinishCount}</p>
                                </div>}
                            {tasksFinishedCount > 0 &&
                                <div className="sprint-progress-bar-finish"
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: true,
                                         planned: true,
                                         count: tasksFinishedCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                        prevState.includes('finish') ? prevState.filter((el: string) =>
                                            el !== 'finish') : [...prevState, 'finish'])}
                                     style={{
                                         minWidth: `calc(
                                         ${Math.min(100, tasksFinishedCount / totalFinishCount * 100)}% - ${
                                             Math.min(100, tasksFinishedCount / totalFinishCount * 59)}px)`,
                                     }}>
                                    <p className="progress-total-inside">{tasksFinishedCount}</p>
                                </div>}
                            {tasksFinishedCount === 0 && allFinishedTasksCount === 0 &&
                                <p className="progress-total-outside">0</p>}
                            <div className="finish-line">
                                <span>
                                    <p>{`${tasksDueToFinishCount}|`}</p>
                                    <FontAwesomeIcon icon={faFlagCheckered}
                                                     className="finish-line-icon"
                                    />
                                </span>
                            </div>
                            {/*{tasksFinishedCount / tasksDueToFinishCount > 1 ?*/}
                            {/*    <div className="over-progressed"*/}
                            {/*         style={{marginLeft: `-${((tasksFinishedCount - tasksDueToFinishCount) / tasksFinishedCount) * 100}%`}}*/}
                            {/*    ></div> : null}*/}
                        </div>
                    </div>
                </span>
            </div>

            <div className="sprint-timeline">
                {((new Date().getTime() - activeDataVersion.version.dataVersionDate.toDate().getTime()) / 6048000) < 100 ?
                    <div className="sprint-timeline-progress" style={{width: `calc(${(new Date().getTime() - activeDataVersion.version.dataVersionDate.toDate().getTime()) / 6048000}% - 20px)`}}></div> :
                    <div className="sprint-timeline-progress full" style={{width: `calc(100% - 20px)`}}></div>}
                <div className="sprint-day">
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).format('ddd').toUpperCase()}</p>
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).format('DD')}</p>
                </div>
                <div className="sprint-day">
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(1, 'day').format('ddd').toUpperCase()}</p>
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(1, 'day').format('DD')}</p>
                </div>
                <div className="sprint-day">
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(2, 'day').format('ddd').toUpperCase()}</p>
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(2, 'day').format('DD')}</p>
                </div>
                <div className="sprint-day">
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(3, 'day').format('ddd').toUpperCase()}</p>
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(3, 'day').format('DD')}</p>
                </div>
                <div className="sprint-day">
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(4, 'day').format('ddd').toUpperCase()}</p>
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(4, 'day').format('DD')}</p>
                </div>
                <div className="sprint-day">
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(5, 'day').format('ddd').toUpperCase()}</p>
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(5, 'day').format('DD')}</p>
                </div>
                <div className="sprint-day">
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(6, 'day').format('ddd').toUpperCase()}</p>
                    <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(6, 'day').format('DD')}</p>
                </div>
            </div>
            <div className="tasks-sort-header"
                 style={{
                     // position: "sticky",
                     // top: "0",
                     backgroundColor: "rgb(255 255 255 / 80%)",
                     zIndex: "200"
                 }}>
                <div className="priority-task">
                    <div
                        className={"priority " + (sortCriteria === "index" ? "active" : "")}
                        onClick={() => setSortCriteria("index")}
                    >
                        <span className="priority-text">Priority</span>&nbsp;
                    </div>
                    <div className="task-desc">Deliverable</div>
                </div>
                <div className="right-filters">
                    {viewWidth >= 1200 &&
                        <div className="task-header narrow" style={{textAlign: "center"}}>
                            Start
                        </div>}
                    {viewWidth >= 1200 &&
                        <div className="task-header narrow" style={{textAlign: "center"}}>
                            Finish
                        </div>}
                    <div style={{textAlign: "right"}}
                         className={"narrow time " + (sortCriteria === "target" ? "active" : "")}
                         onClick={() => setSortCriteria("target")}
                    >
                        Target
                    </div>
                    <div style={{textAlign: "right"}}
                         className={"task-header critical " + (sortCriteria === "deadline" ? "active" : "")}
                         onClick={() => setSortCriteria("deadline")}
                    >Deadline
                    </div>
                </div>
            </div>
            {filtersLoaded ?
                <div className="lazy-load-container sprint">
                    {projectView === "task-map" && <GoogleMapVisGL/>}
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={loadMoreHandler}
                        initialLoad={false}
                        hasMore={
                            view === "future"
                                ? !loading.QueuedIsLoading && moreTasks.queued
                                : !loading.ConfirmedCompleteIsLoading && moreTasks.confirmedComplete
                        }
                        useWindow={false}
                    >
                        <div className="lazy-load-content" style={{zIndex: "100"}}>
                            {filteredArchivedTaskList.length > 0 ? (
                                <TaskListSection
                                    text="Archive"
                                    moreTasks={moreTasks.confirmedComplete}
                                    isLoading={false}
                                    headline={"Archive"}
                                    title={
                                        "Archive"
                                    }
                                    taskList={filteredArchivedTaskList}
                                    view={view}
                                    setView={setView}
                                    total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                    quality={false}
                                    type={"task-sprint"}
                                    open={archivedOpen}
                                    setOpen={setArchivedOpen}
                                    taskSnapshot={taskSnapshot}
                                />
                            ) : null}
                            {filteredDeclaredTaskList.length > 0 ? (
                                <TaskListSection
                                    text="Ready for Sign-off"
                                    moreTasks={moreTasks.confirmedComplete}
                                    isLoading={false}
                                    headline={"Ready for Sign-off"}
                                    title={"Ready for Sign-off"}
                                    taskList={filteredDeclaredTaskList}
                                    view={view}
                                    setView={setView}
                                    total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                    quality={false}
                                    type={"task-sprint"}
                                    open={declaredOpen}
                                    setOpen={setDeclaredOpen}
                                    taskSnapshot={taskSnapshot}
                                />
                            ) : null}
                            {filteredInProgressTaskList.length > 0 ? (
                                <TaskListSection
                                    text="Work in progress"
                                    moreTasks={moreTasks.confirmedComplete}
                                    isLoading={false}
                                    headline={"Work in progress"}
                                    title={"Work in progress"}
                                    taskList={filteredInProgressTaskList}
                                    view={view}
                                    setView={setView}
                                    total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                    quality={false}
                                    type={"task-sprint"}
                                    open={inProgressOpen}
                                    setOpen={setInProgressOpen}
                                    taskSnapshot={taskSnapshot}
                                />
                            ) : null}
                            {filteredQueuedTaskList.length > 0 ? (
                                <TaskListSection
                                    text="Queued"
                                    moreTasks={moreTasks.confirmedComplete}
                                    isLoading={false}
                                    headline={"Queued"}
                                    title={"Queued"}
                                    taskList={filteredQueuedTaskList}
                                    view={view}
                                    setView={setView}
                                    total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                    quality={false}
                                    type={"task-sprint"}
                                    open={queuedOpen}
                                    setOpen={setQueuedOpen}
                                    taskSnapshot={taskSnapshot}
                                />
                            ) : null}
                            {(view === "future"
                                ? loading.QueuedIsLoading
                                : loading.ConfirmedCompleteIsLoading) && (
                                <div className="load-more-items-wrap" key={0}>
                                    <Loader active/>
                                </div>
                            )}
                        </div>
                    </InfiniteScroll>
                </div> : <Loader active content={"Loading sprint..."} size={"large"}/>
            }
            {sprintPopup &&
                <div className="sprint-popup"
                     style={{top: sprintPopup.y + 20, left: sprintPopup.x}}
                >
                    <h4><b>{sprintPopup.count} {
                        sprintPopup.early ?
                            sprintPopup.finish ?
                                "sprint tasks finished before sprint window" : "sprint tasks started before sprint window" :
                            sprintPopup.finish ?
                                sprintPopup.planned ? "sprint tasks finished" : "non-sprint tasks finished" :
                                sprintPopup.planned ? "sprint tasks started" : "non-sprint tasks started"}</b></h4>
                </div>
            }
        </div>
    );
};


