import {Timestamp} from "firebase/firestore";

export enum SprintTaskType {
    DID_NOT_START = 'DNS',
    STARTED = 'STD',
    DID_NOT_FINISH = 'DNF',
    FINISHED = 'FIN',
    PROGRESSED = 'PRO',
    NO_PROGRESS = 'NOP',
    DID_NOT_START_AND_FINISH = 'DSF',
    STARTED_AND_FINISHED = 'SAF',
}

export enum SprintCategory {
    START = 'S',
    PROGRESS = 'PRO',
    FINISH = 'F',
    START_AND_FINISH = 'SAF',
}

export class SprintDataModel {
    contractId: string;
    cutOffDate: Timestamp;
    dataVersionId: string;
    finishCount: number;
    periodStart: Timestamp;
    sprintTasks: string []; // task codes
    startCount: number;
    totalSprintCount: number;
    actualStarts?: number;
    actualFinishes?: number;
}
