import { ThunkResult } from "../store.types";
import TaskModel, { ProcessedFrom } from "../../models/responses/task.model";
import FirebaseUsage from "../../firebase/firebase.usage";
import { COLLECTIONS } from "../../firebase/constants";
import TaskStatusModel from "../../models/responses/task-status.model";
import { HttpsCallableResult } from "@firebase/functions-types";
import * as taskActions from "../actions/task.actions";
import * as projectActions from "../actions/project.actions";
import TaskTransactions from "./transactions/task.transactions";
import { SuspendReasons } from "../../models/suspend-reason.enum";
import { UserDocModel } from "../../models/responses/user.model";
import { CreateNewTaskBody } from "./transactions/types/transactions.types";
import { EvaluationOptions } from "../../models/evaluation-options.enum";
import { MessageType } from "../../models/responses/message.model";
import { CheckList } from "../../models/checklist-model";
import { Timestamp } from "firebase/firestore";
import { subsManager } from "../middleware/subs-manager/subs-manager";
import store from "../store";
import { TaskListSectionModel } from "../../models/task-list-section.model";
import { taskMiddleware } from "../middleware/middlewares/task.middleware";
import * as tasksActions from "../actions/task.actions";
import ImageModel from "../../models/responses/image.model";
import LedgerEntry from "../../models/responses/ledger-entry.model";
import {v4 as uuidv4} from "uuid";
import ProjectFileModel from "../../models/responses/project-file.model";
import {useUserSelector} from "../selectors/authorization.selectors";
import {userHasSeen} from "../../utils/taskforce.utils";
import CpmFunctionsController from "../../utils/cpm-functions/cpm-functions.controller";
// import {
//   useCompleteTaskListSelector,
//   useDeclaredCompleteTaskListSelector,
//   useInProgressTaskListSelector,
//   useQueuedTaskListSelector,
// } from "../selectors/task/task.selectors";
// import { RootStateModel } from "../reducers";

const activeStatuses = [
  TaskStatusModel.NOT_STARTED,
  TaskStatusModel.IN_PROGRESS,
  TaskStatusModel.BLOCK,
  TaskStatusModel.SUSPENDED,
  TaskStatusModel.COMPLETE,
  TaskStatusModel.DECLARED_COMPLETE,
];

let activeTaskId;

export class TaskThunk {
  static async refreshTaskList(
    taskListType: TaskListSectionModel | ProcessedFrom | null,
    activeProject,
    task?: TaskModel
  ) {
    console.log("👾 refreshTaskList");
    if (taskListType === TaskListSectionModel.WORK_IN_PROCESS) {
      subsManager.removeTaskListSubscription("in-progress-task-list");
      subsManager.subscribeInProgressTaskList({
        project_id: activeProject,
        taskListType: TaskListSectionModel.WORK_IN_PROCESS,
        statuses: activeStatuses,
        taskForces: store.getState().search.parameters.taskForces,
      });
    } else if (taskListType === TaskListSectionModel.CONFIRMED_COMPLETE) {
      subsManager.removeTaskListSubscription("confirmed-complete-task-list");
      subsManager.subscribeConfirmedCompleteTaskList({
        project_id: activeProject,
        taskListType: TaskListSectionModel.CONFIRMED_COMPLETE,
        statuses: activeStatuses,
        taskForces: store.getState().search.parameters.taskForces,
      });
    } else if (taskListType === TaskListSectionModel.DECLARED_COMPLETE) {
      subsManager.removeTaskListSubscription("declared-complete-task-list");
      subsManager.subscribeDeclaredCompleteTaskList({
        project_id: activeProject,
        taskListType: TaskListSectionModel.DECLARED_COMPLETE,
        statuses: activeStatuses,
        taskForces: store.getState().search.parameters.taskForces,
      });
    } else if (taskListType === TaskListSectionModel.QUEUED) {
      subsManager.removeTaskListSubscription("queued-task-list");
      subsManager.subscribeQueuedTaskList({
        project_id: activeProject,
        taskListType: TaskListSectionModel.QUEUED,
        statuses: activeStatuses,
        taskForces: store.getState().search.parameters.taskForces,
      });
    } else if (taskListType === TaskListSectionModel.PENDING) {
      subsManager.removeTaskListSubscription("pending-task-list");
      subsManager.subscribePendingTaskList({
        project_id: activeProject,
        taskListType: TaskListSectionModel.PENDING,
        statuses: activeStatuses,
        taskForces: store.getState().search.parameters.taskForces,
      });
    }
  }

  static getRelevantTaskList(taskListType: TaskListSectionModel, state) {
    switch (taskListType) {
      case TaskListSectionModel.WORK_IN_PROCESS:
        return state().task.tasks.inProgress;
      case TaskListSectionModel.CONFIRMED_COMPLETE:
        return state().task.tasks.confirmedComplete;
      case TaskListSectionModel.DECLARED_COMPLETE:
        return state().task.tasks.declaredComplete;
      case TaskListSectionModel.QUEUED:
        return state().task.tasks.queued;
      case TaskListSectionModel.PENDING:
        return state().task.tasks.pending;
      default:
        return [];
    }
  }

  static setRelevantTaskList(
    taskListType: TaskListSectionModel,
    tasks: TaskModel[]
  ) {
    console.log("👾 setRelevantTaskList");
    switch (taskListType) {
      case TaskListSectionModel.WORK_IN_PROCESS:
        store.dispatch({
          type: taskActions.ActionNames.SET_REFRESH_TASK_LIST,
          payload: { refreshTaskList: true },
        });
        return store.dispatch(
          tasksActions.Actions.setInProgressTaskList(tasks)
        );
      case TaskListSectionModel.CONFIRMED_COMPLETE:
        return store.dispatch(
          tasksActions.Actions.setConfirmedCompleteTaskList(tasks)
        );
      case TaskListSectionModel.DECLARED_COMPLETE:
        return store.dispatch(
          tasksActions.Actions.setDeclaredCompleteTaskList(tasks)
        );
      case TaskListSectionModel.QUEUED:
        return store.dispatch(tasksActions.Actions.setQueuedTaskList(tasks));
      case TaskListSectionModel.PENDING:
        return store.dispatch(tasksActions.Actions.setPendingTaskList(tasks));
      default:
        return [];
    }
  }

  static createNewTask(
    body: CreateNewTaskBody | CreateNewTaskBody[]
  ): ThunkResult<Promise<void>> {
    return async (_, getState) => {
      console.log("👾 createNewTask");
      const currentUser = getState().authorization.user;
      const activeProject = getState().project.activeProject;

      if (!currentUser) {
        return console.error("No authorized user");
      }
      const ref: any = FirebaseUsage.getBlankDoc(COLLECTIONS.TASKS);

      const getNewTask = (
        newTaskBody: Partial<Pick<TaskModel, "task_id">> & CreateNewTaskBody
      ): Partial<TaskModel> => {
        return {
          ...newTaskBody,
          remainingDuration: newTaskBody.remainingDuration,
          late_end_date: Timestamp.fromMillis(
            new Date().getTime() + newTaskBody.late_end_date.seconds * 100
          ),
          late_start_date: Timestamp.fromMillis(
            new Date().getTime() + newTaskBody.late_end_date.seconds * 100
          ),
          act_start_date: null,
          act_end_date: null,
          task_id: newTaskBody.task_id || ref.id,
          evaluated: false,
          blocked: false,
          status: TaskStatusModel.NOT_STARTED,
          taskForce: [currentUser.userId],
          suspended: false,
        };
      };

      if (Array.isArray(body)) {
        await TaskTransactions.addNewTaskList(
          body.map((el) => getNewTask(el)),
          activeProject!.projectId
        );
      } else {
        const newTask: Partial<TaskModel> = getNewTask(body);
        await ref.set(newTask);
      }
    };
  }

  static leaveTaskForce(
    taskId: string,
    userId: string,
    messageType: MessageType,
    eventTimestamp: Timestamp,
    taskListType: TaskListSectionModel
  ): ThunkResult<Promise<void>> {
    console.log("👾 leaveTaskForce");
    return async (dispatch, getState) => {
      try {
        const user = getState().authorization.user;
        const activeProject = getState().project.activeProject;
        const memberId =
          getState().authorization.membersMap[activeProject!.projectId]!
            .memberId;
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.leaveTaskForce(
          taskId,
          activeProject!.projectId,
          userId,
          messageType,
          eventTimestamp,
          user,
          memberId
        ).then(() => {
          TaskThunk.refreshTaskList(taskListType, activeProject!.projectId);
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
        });
      } catch (e) {
        console.error("leaveTaskForce Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static async updateTaskProgress(task, progress) {
    const existingProgress = task.progress || [];
    const userId = store.getState().authorization.user!.userId;
    const date = FirebaseUsage.timestamp();
    const newProgress = [...existingProgress, { userId, progress, date }];
    if (userId) {
      return FirebaseUsage.updateDoc(COLLECTIONS.TASKS, task.task_id, {
        progress: newProgress,
      });
    }
    return;
  }

  static approveNewTask(taskId: string) {
    return async (dispatch, getState) => {
      const user = getState().authorization.user;
      const activeProject = getState().project.activeProject;
      dispatch({
        type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
        payload: { taskId },
      });
      CpmFunctionsController.runTaskCPM({
        project_id: activeProject.projectId,
        task_id: taskId,
        event_type: "approveNew",
        seconds: new Date().getTime() / 1000,
        user_id: user.userId,
        user_email: user.userEmail,
        expiry_date: new Date().getTime() / 1000,
        suspend_reason: "No reason",
        reason_text: "No reason",
        sprint_id: undefined,
      }, dispatch).then(() => {
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
          payload: { taskId },
        });
      });
    }
  }

  static joinTaskForce(
    taskId: string,
    userId: string,
    messageType: MessageType,
    eventTimestamp: Timestamp,
    taskListType: TaskListSectionModel
  ): ThunkResult<Promise<void>> {
    console.log("👾 joinTaskForce");
    return async (dispatch, getState) => {
      try {
        const user = getState().authorization.user;
        const activeProject = getState().project.activeProject;
        const memberId =
          getState().authorization.membersMap[activeProject!.projectId]!
            .memberId;
        const oldTaskList = TaskThunk.getRelevantTaskList(
          taskListType,
          getState
        );
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.joinTaskForce(
          taskId,
          activeProject!.projectId,
          userId,
          messageType,
          eventTimestamp,
          user,
          memberId
        ).then(() => {
          FirebaseUsage.getDoc(COLLECTIONS.TASKS, taskId).then((doc) => {
            const newTaskList: any[] = oldTaskList.map((task) => {
              if (task.task_id === taskId) {
                return {
                  ...doc.data(),
                  index: task.index,
                };
              }
              return task;
            });
            TaskThunk.setRelevantTaskList(taskListType, newTaskList);
            TaskThunk.refreshTaskList(taskListType, activeProject!.projectId);
            dispatch({
              type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
              payload: { taskId },
            });
          });
        });
      } catch (e) {
        console.error("joinTaskForce Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static addTaskForce(
    taskId: string,
    userEmail: string,
    whoAdded: string,
    type: string,
    groupId: string,
    taskListType: TaskListSectionModel | ProcessedFrom | null
  ): ThunkResult<Promise<void>> {
    console.log("👾 joinTaskForce");
    return async (dispatch, getState) => {
      try {
        const user = getState().authorization.user;
        const activeProject = getState().project.activeProject;
        const selectedMember = getState().team.memberList.find(
          (member) => member.userEmail === userEmail
        );
        if (!selectedMember) {
          throw new Error("Member does not exist");
        }
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.addTaskForce(
          taskId,
          activeProject!.projectId,
          selectedMember!.memberId,
          whoAdded,
          type,
          groupId,
          user
        ).then(() => {
          TaskThunk.refreshTaskList(taskListType, activeProject!.projectId);
          subsManager.subscribeLedgerList(taskId);
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
        });
      } catch (e) {
        console.error("addTaskforce Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static removeTaskForce(
    taskId: string,
    userEmail: string,
    taskListType: TaskListSectionModel | ProcessedFrom | null,
    type: string,
    groupId: string
  ): ThunkResult<Promise<void>> {
    console.log("👾 removeTaskForce");
    return async (dispatch, getState) => {
      try {
        const activeProject = getState().project.activeProject;
        const selectedMember = getState().team.memberList.find(
          (member) => member.userEmail === userEmail
        );
        if (!selectedMember) {
          throw new Error("Member does not exist");
        }
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.removeTaskForce(
          taskId,
          activeProject!.projectId,
          selectedMember!.userId,
          type,
          groupId
        ).then(() => {
          TaskThunk.refreshTaskList(taskListType, activeProject!.projectId);
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
        });
      } catch (e) {
        console.error("removeTaskforce Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static sendPost(
    taskId: string,
    text: string,
    user: UserDocModel,
    taskListType: TaskListSectionModel,
    activeTask: TaskModel,
    taggedUsers: string[] = []
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      try {
        const activeProject = getState().project.activeProject;
        if (!activeProject) {
          throw new Error("No active project");
        }
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        const ledgerRef = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY);
        const ledgerData: LedgerEntry = {
          ledgerId: ledgerRef.id,
          taskId: taskId,
          projectId: activeProject!.projectId,
          userId: user.userId!,
          userEmail: user.userEmail,
          text: text,
          timestamp: FirebaseUsage.timestamp(),
          logTimestamp: FirebaseUsage.timestamp(),
          type: MessageType.MSG,
          taggedUsers,
        };
        FirebaseUsage.setDocumentWithDoc(ledgerRef, ledgerData).then(() => {
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: {taskId},
          });
          userHasSeen(user.userId!, activeTask).catch((e) => console.log("Error: ", e));
        });
        subsManager.subscribeLedgerList(taskId);
      } catch (e) {
        console.error("sendPost Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static sendPostWithImages(
      taskId: string,
      user: UserDocModel,
      text: string,
      attachments: any[],
      activeTask: TaskModel,
      taggedUsers: string[] = []
  ) {
    function convertDegreesMinutesSecondsToDecimal(gpsData) {
        const [degrees, minutes, seconds] = gpsData;
        return degrees + minutes / 60 + seconds / 3600;
    }

    function convertDateTime (dateTime) {
        const [date, time] = dateTime.split(' ')
        const [year, month, day] = date.split(':')
        const [hour, minute, second] = time.split(':')
        return new Date(year, month -1, day, hour, minute, second)
    }

    return async (dispatch, getState) => {
      try {
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        let attachmentList: any[] = []
        const ledgerRef = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY);
        const activeProject = getState().project.activeProject;
        const storageId = await FirebaseUsage.getDoc(COLLECTIONS.PROJECT_STORAGE, activeProject!.storageId)
            .then((doc) => doc.data()!.storageBucketId)
        attachments.forEach((attachment) => {
          const imageRef = FirebaseUsage.getBlankDoc(COLLECTIONS.IMAGES);
          const fileId = uuidv4();
          FirebaseUsage.uploadFileToStorage(`${storageId}/project_images/${fileId}`, attachment.arrayBuffer).catch((e) => console.log("Error: ", e));
          attachmentList.push(imageRef.id);
          const uploadData: ImageModel = {
            imageId: imageRef.id,
            modified: FirebaseUsage.timestamp(new Date(attachment.modified)),
            uploaded: FirebaseUsage.timestamp(),
            ledgerId: ledgerRef.id,
            fileName: attachment.filename,
            relatedText: text,
            taskId: taskId,
            projectId: activeProject!.projectId,
            uploadUserId: user.userId!,
            type: attachment.type,
            src: attachment.src,
            storagePath: `${storageId}/project_images/${fileId}`,
            hasGeoData: attachment.geoData ? attachment.geoData.GPSLatitude ? true : false : false,
          };
          FirebaseUsage.setDocumentWithDoc(imageRef, uploadData).catch((e) => console.log("Error: ", e));
          if (attachment.geoData && attachment.geoData.GPSLatitude && attachment.geoData.GPSLongitude) {
            const geoDataRef = FirebaseUsage.getBlankDoc(COLLECTIONS.GEO_DATA);
            const geoDataUpload = {
                imageId: imageRef.id,
                geoId: geoDataRef.id,
                text: text,
                taskId: taskId,
                projectId: activeProject!.projectId,
                userId: user.userId!,
                gpsLat: convertDegreesMinutesSecondsToDecimal(attachment.geoData.GPSLatitude),
                gpsLong: convertDegreesMinutesSecondsToDecimal(attachment.geoData.GPSLongitude) * -1,
                gpsElevation: attachment.geoData.GPSAltitude ? parseFloat(attachment.geoData.GPSAltitude) : null,
                gpsDirection: attachment.geoData.GPSImgDirection ? parseFloat(attachment.geoData.GPSImgDirection) : null,
                dateCaptured: attachment.geoData.DateTime ? FirebaseUsage.timestamp(convertDateTime(attachment.geoData.DateTime)) : null,
                type: "image"
            }
            FirebaseUsage.setDocumentWithDoc(geoDataRef, geoDataUpload).catch((e) => console.log("Error: ", e));
          }
        });
        const ledgerData: LedgerEntry = {
            ledgerId: ledgerRef.id,
            taskId: taskId,
            projectId: activeProject!.projectId,
            userId: user.userId!,
            userEmail: user.userEmail,
            text: text,
            attachments: attachmentList,
            timestamp: FirebaseUsage.timestamp(),
            logTimestamp: FirebaseUsage.timestamp(),
            type: MessageType.IMG,
            taggedUsers
        };
        FirebaseUsage.setDocumentWithDoc(ledgerRef, ledgerData).then(() => {
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
          userHasSeen(user.userId!, activeTask).catch((e) => console.log("Error: ", e));
        }).catch((e) => console.log("Error: ", e));
      subsManager.subscribeLedgerList(taskId);
      } catch (e) {
        console.error("sendPost Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static sendPostWithFiles(
    taskId: string,
    user: UserDocModel,
    text: string,
    attachments: any[],
    activeTask: TaskModel,
    taggedUsers: string[] = []
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      try {
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        let attachmentList: any[] = [];
        const ledgerRef = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY);
        const activeProject = getState().project.activeProject;
        const storageId = await FirebaseUsage.getDoc(COLLECTIONS.PROJECT_STORAGE, activeProject!.storageId)
            .then((doc) => doc.data()!.storageBucketId)
        attachments.forEach((attachment) => {
          const fileId = uuidv4();
          FirebaseUsage.uploadFileToStorage(`${storageId}/project_files/${fileId}`, attachment.arrayBuffer).catch((e) => console.log("Error: ", e));
          const projectFileRef = FirebaseUsage.getBlankDoc(COLLECTIONS.PROJECT_FILES);
          const uploadData: ProjectFileModel = {
            fileId: projectFileRef.id,
            modified: FirebaseUsage.timestamp(new Date(attachment.modified)),
            uploaded: FirebaseUsage.timestamp(),
            storagePath: `${storageId}/project_files/${fileId}`,
            fileName: attachment.fileName,
            projectId: activeProject!.projectId,
            userId: user.userId!,
            type: attachment.type,
            taskId: taskId,
            ledgerId: ledgerRef.id,
          }
          attachmentList.push(projectFileRef.id);
          FirebaseUsage.setDocumentWithDoc(projectFileRef, uploadData).catch((e) => console.log("Error: ", e));
        });
        const ledgerData: LedgerEntry = {
          ledgerId: ledgerRef.id,
          taskId: taskId,
          projectId: activeProject!.projectId,
          userId: user.userId!,
          userEmail: user.userEmail,
          text: text,
          attachments: attachmentList,
          timestamp: FirebaseUsage.timestamp(),
          logTimestamp: FirebaseUsage.timestamp(),
          type: MessageType.FIL,
          taggedUsers
        };
        FirebaseUsage.setDocumentWithDoc(ledgerRef, ledgerData).then(() => {
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
          userHasSeen(user.userId!, activeTask).catch((e) => console.log("Error: ", e));
        })
        subsManager.subscribeLedgerList(taskId);
      } catch (e) {
        console.error("sendPost Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static updateUserTaskSeen(
    taskId: string,
    user: UserDocModel,
    seen: boolean,
    taskListType: TaskListSectionModel,
    task: TaskModel
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      try {
        const activeProject = getState().project.activeProject;
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        // TaskTransactions.updateUserTaskSeen(taskId, user, seen).then(() => {
        FirebaseUsage.updateDoc(COLLECTIONS.TASKS, taskId, {
          users: task.users!.map((taskUser) => {
            if (taskUser.userId === user.userId) {
              return { userId: user.userId, seen: seen };
            }
            return taskUser;
          }),
        }).then(() => {
          // TaskThunk.refreshTaskList(taskListType, activeProject!.projectId, task);
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
        });
      } catch (e) {
        console.error("updateUserTaskSeen Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static setUserHasSeen(
      taskId: string,
      userId: string,
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      try {
        const activeProject = getState().project.activeProject;
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        const taskData = await FirebaseUsage.getDoc(COLLECTIONS.TASKS, taskId)
            .then((res) => res.data() as TaskModel)
        const users = taskData.taskForce.length > 0 ?
            taskData.taskForce
                .map((user) => ({userId: user, seen: user === userId}))
            : []
        FirebaseUsage.updateDoc(COLLECTIONS.TASKS, taskId, {users: users})
            .then(() => {
                dispatch({
                  type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
                  payload: { taskId },
                });
            });
      } catch (e) {
        console.error("updateUserTaskSeen Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static runActiveTask(
    taskId: string,
    userEmail: string,
    messageType: MessageType,
    eventTimestamp: Timestamp,
    prevTaskListType: TaskListSectionModel
  ): ThunkResult<Promise<void>> {
    console.log("👾 runActiveTask");
    return async (dispatch, getState) => {
      const user = getState().authorization.user;
      const activeProject = getState().project.activeProject;
      console.log(activeProject);
      const oldTaskList = TaskThunk.getRelevantTaskList(
        prevTaskListType,
        getState
      );
      const oldDestinationTaskList = TaskThunk.getRelevantTaskList(
        TaskListSectionModel.WORK_IN_PROCESS,
        getState
      );
      const newTaskList: TaskModel[] = oldTaskList.filter(
        (task) => task.task_id !== taskId
      );
      try {
        activeTaskId = taskId;
        dispatch({
          type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
          payload: { value: true },
        });
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.runActiveTask(
          taskId,
          activeProject!.projectId,
          user!,
          userEmail,
          messageType,
          eventTimestamp
        ).then(() => {
          FirebaseUsage.getDoc(COLLECTIONS.TASKS, taskId).then((res) => {
            TaskThunk.refreshTaskList(
              prevTaskListType,
              activeProject?.projectId
            );
            if (prevTaskListType === TaskListSectionModel.WORK_IN_PROCESS) {
              TaskThunk.refreshTaskList(
                TaskListSectionModel.WORK_IN_PROCESS,
                activeProject?.projectId
              );
            }
            dispatch({
              type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
              payload: { taskId },
            });
            dispatch({
              type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
              payload: { value: activeTaskId === taskId ? false : true },
            });
            const destinationTaskList = oldDestinationTaskList.concat(
              res.data()
            );
            destinationTaskList.forEach((task) => {
              if (!task.index) {
                task.index = destinationTaskList.length;
              }
            });

            // TaskThunk.setRelevantTaskList(prevTaskListType, newTaskList);
            if (prevTaskListType === TaskListSectionModel.QUEUED) {
              TaskThunk.setRelevantTaskList(
                TaskListSectionModel.WORK_IN_PROCESS,
                destinationTaskList
              );
            }
          });
        });
      } catch (e) {
        console.error("runActiveTask Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
        dispatch({
          type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
          payload: { value: activeTaskId === taskId ? false : true },
        });
      }
    };
  }

  static refreshTasks(task: TaskModel, projectId: string, dispatch: any) {
    console.log("👾 refreshTasks");
    let taskId = task.task_id;
    dispatch({
      type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
      payload: { value: true },
    });
    TaskTransactions.refreshAfterUndo(task, projectId)
      .then(() => {
        TaskThunk.refreshTaskList(
          TaskListSectionModel.WORK_IN_PROCESS,
          projectId
        ).catch((e) => console.error("refreshTasks Error: ", e));
        TaskThunk.refreshTaskList(
          TaskListSectionModel.CONFIRMED_COMPLETE,
          projectId
        ).catch((e) => console.error("refreshTasks Error: ", e));
        TaskThunk.refreshTaskList(
          TaskListSectionModel.DECLARED_COMPLETE,
          projectId
        ).catch((e) => console.error("refreshTasks Error: ", e));
        TaskThunk.refreshTaskList(TaskListSectionModel.QUEUED, projectId).catch(
          (e) => console.error("refreshTasks Error: ", e)
        );
        dispatch({
          type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
          payload: { value: false },
        });
      })
      .catch((e) => console.error("refreshTasks Error: ", e));
    dispatch({
      type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
      payload: { taskId },
    });
    return async () => {
      return;
    };
  }

  static evaluateCompletionTask(
    taskId: string,
    accepted: boolean,
    userEmail: string,
    messageType: MessageType,
    eventTimestamp: Timestamp,
    taskListType: TaskListSectionModel
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      console.log("👾 evaluateCompletionTask");
      const user = getState().authorization.user;
      const activeProject = getState().project.activeProject;
      try {
        // dispatch({ type: taskActions.ActionNames.TASK_TOOLBAR_LOADING, payload: { taskId } });
        await TaskTransactions.evaluateCompletionTask(
          taskId,
          activeProject!.projectId,
          user!,
          accepted,
          userEmail,
          messageType,
          eventTimestamp
        );
        TaskThunk.refreshTaskList(taskListType, activeProject!.projectId);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
          payload: { taskId },
        });
      } catch (e) {
        console.error("completeTask Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static completeChecklistNotification(
    taskId: string
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      console.log("👾 completeChecklistNotification");
      const user = getState().authorization.user;
      const activeProject = getState().project.activeProject;
      try {
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.completeChecklistNotification(
          taskId,
          activeProject!.projectId,
          user!
        );
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
          payload: { taskId },
        });
      } catch (e) {
        console.error("completeTask Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static taskBehindScheduleNotification(
    taskId: string
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      console.log("👾 taskBehindScheduleNotification");
      const user = getState().authorization.user;
      const activeProject = getState().project.activeProject;
      try {
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.taskBehindScheduleNotification(
          taskId,
          activeProject!.projectId,
          user!
        );
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
          payload: { taskId },
        });
      } catch (e) {
        console.error("completeTask Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static assignedToTaskforceNotification(
    taskId: string
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      console.log("👾 assignedToTaskforceNotification");
      const user = getState().authorization.user;
      const activeProject = getState().project.activeProject;
      try {
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.assignedToTaskforceNotification(
          taskId,
          activeProject!.projectId,
          user!
        );
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
          payload: { taskId },
        });
      } catch (e) {
        console.error("completeTask Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static evaluateCompletionTasks(): ThunkResult<Promise<void>> {
    console.log("👾 evaluateCompletionTasks");
    return async (dispatch, getState) => {
      const user = useUserSelector()
      const activeProject = getState().project.activeProject;
      try {
        // dispatch({ type: taskActions.ActionNames.TASK_TOOLBAR_LOADING, payload: { taskId } });
        await TaskTransactions.evaluateCompletionTasks(
          user,
          activeProject!.projectId
        );
      } catch (e) {
        console.error("completeTask Error: ", e);
      }
    };
  }

  static declareCompleteTask(
    taskId: string,
    userEmail: string,
    messageType: MessageType,
    eventTimestamp: Timestamp
  ): ThunkResult<Promise<void>> {
    console.log("👾 declareCompleteTask");
    return async (dispatch, getState) => {
      const user = getState().authorization.user;
      const activeProject = getState().project.activeProject;
      try {
        activeTaskId = taskId;
        dispatch({
          type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
          payload: { value: true },
        });
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.declareCompleteTask(
          taskId,
          activeProject!.projectId,
          user!,
          userEmail,
          messageType,
          eventTimestamp
        ).then(() => {
          TaskThunk.refreshTaskList(
            TaskListSectionModel.WORK_IN_PROCESS,
            activeProject?.projectId
          );
          TaskThunk.refreshTaskList(
            TaskListSectionModel.DECLARED_COMPLETE,
            activeProject?.projectId
          );
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
          dispatch({
            type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
            payload: { value: activeTaskId === taskId ? false : true },
          });
          dispatch({
            type: taskActions.ActionNames.SET_REFRESH_TASK_LIST,
            payload: { refreshTaskList: true },
          });
        });
      } catch (e) {
        console.error("completeTask Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
        dispatch({
          type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
          payload: { value: activeTaskId === taskId ? false : true },
        });
      }
    };
  }

  // static setBlockTask(
  //   taskId: string,
  //   suspendReason: string,
  //   block: boolean,
  //   taskListType
  // ): ThunkResult<Promise<void>> {
  //   console.log("👾 setBlockTask");
  //   return async (dispatch, getState) => {
  //     const activeProject = getState().project.activeProject;
  //     try {
  //       dispatch({
  //         type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
  //         payload: { taskId },
  //       });
  //       TaskTransactions.setBlockTask(
  //         taskId,
  //         activeProject!.projectId,
  //         suspendReason,
  //         block
  //       ).then(() => {
  //         FirebaseUsage.getDoc(COLLECTIONS.TASKS, taskId).then((task) => {
  //           const newTask = task.data() as TaskModel;
  //           const newTaskListType = newTask.taskListType;
  //           dispatch({
  //             type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
  //             payload: { taskId },
  //           });
  //           TaskThunk.refreshTaskList(taskListType, activeProject?.projectId);
  //           if (taskListType !== newTaskListType) {
  //             TaskThunk.refreshTaskList(
  //               newTaskListType,
  //               activeProject?.projectId
  //             );
  //           }
  //         });
  //       });
  //     } catch (e) {
  //       console.error("setBlockTask Error: ", e);
  //       dispatch({
  //         type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
  //         payload: { taskId },
  //       });
  //     }
  //   };
  // }

  static suspendActiveTask(
    taskId: string,
    suspendReason: SuspendReasons | string,
    userEmail: string,
    messageType: MessageType,
    eventTimestamp: Timestamp
  ): ThunkResult<Promise<void>> {
    console.log("👾 suspendActiveTask");
    return async (dispatch, getState) => {
      const user = getState().authorization.user;
      const activeProject = getState().project.activeProject;
      try {
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.suspendActiveTask(
          taskId,
          activeProject!.projectId,
          suspendReason,
          user!,
          userEmail,
          messageType,
          eventTimestamp
        ).then(() => {
          TaskThunk.refreshTaskList(
            TaskListSectionModel.WORK_IN_PROCESS,
            activeProject?.projectId
          );
          TaskThunk.refreshTaskList(
            TaskListSectionModel.QUEUED,
            activeProject?.projectId
          );
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
        });
      } catch (e) {
        console.error("suspendActiveTask Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static changeRemainingDurationOfActiveTask(
    taskId: string,
    forcastDate: Date,
    userEmail: string
  ): ThunkResult<Promise<void>> {
    console.log("👾 changeRemainingDurationOfActiveTask");
    return async (dispatch, getState) => {
      const user = getState().authorization.user;
      const activeProject = getState().project.activeProject;
      try {
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.changeRemainingDurationOfActiveTask(
          taskId,
          activeProject!.projectId,
          forcastDate,
          user!,
          userEmail
        ).then(() => {
          TaskThunk.refreshTaskList(
            TaskListSectionModel.WORK_IN_PROCESS,
            activeProject?.projectId
          );
          TaskThunk.refreshTaskList(
            TaskListSectionModel.QUEUED,
            activeProject?.projectId
          );
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
        });
      } catch (e) {
        console.error("changeRemainingDurationOfActiveTask Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static evaluateActiveTaskCompletion(
    taskId: string,
    result: EvaluationOptions | string,
    userEmail: string,
    messageType: MessageType,
    eventTimestamp: Timestamp
  ): ThunkResult<Promise<void>> {
    console.log("👾 evaluateActiveTaskCompletion");
    return async (dispatch, getState) => {
      const user = getState().authorization.user;
      const activeProject = getState().project.activeProject;
      if (result === EvaluationOptions.APPROVED) {
        try {
          activeTaskId = taskId;
          dispatch({
            type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
            payload: { value: true },
          });
          // dispatch({ type: taskActions.ActionNames.TASK_TOOLBAR_LOADING, payload: { taskId } });
          TaskTransactions.evaluateCompletionTask(
            taskId,
            activeProject!.projectId,
            user!,
            true,
            userEmail,
            messageType,
            eventTimestamp
          ).then(() => {
            TaskThunk.refreshTaskList(
              TaskListSectionModel.DECLARED_COMPLETE,
              activeProject?.projectId
            );
            TaskThunk.refreshTaskList(
              TaskListSectionModel.CONFIRMED_COMPLETE,
              activeProject?.projectId
            );
            dispatch({
              type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
              payload: { taskId },
            });
            dispatch({
              type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
              payload: { value: activeTaskId === taskId ? false : true },
            });
            dispatch({
              type: taskActions.ActionNames.SET_REFRESH_TASK_LIST,
              payload: { refreshTaskList: true },
            });
          });
        } catch (e) {
          console.error("completeTask Error: ", e);
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
            payload: { taskId },
          });
          dispatch({
            type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
            payload: { value: activeTaskId === taskId ? false : true },
          });
        }
      } else {
        try {
          activeTaskId = taskId;
          dispatch({
            type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
            payload: { value: true },
          });
          // dispatch({ type: taskActions.ActionNames.TASK_TOOLBAR_LOADING, payload: { taskId } });
          TaskTransactions.evaluateCompletionTask(
            taskId,
            activeProject!.projectId,
            user!,
            false,
            userEmail,
            messageType,
            eventTimestamp
          ).then(() => {
            TaskThunk.refreshTaskList(
              TaskListSectionModel.DECLARED_COMPLETE,
              activeProject?.projectId
            );
            TaskThunk.refreshTaskList(
              TaskListSectionModel.WORK_IN_PROCESS,
              activeProject?.projectId
            );
            dispatch({
              type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
              payload: { taskId },
            });
            dispatch({
              type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
              payload: { value: activeTaskId === taskId ? false : true },
            });
          });
        } catch (e) {
          console.error("completeTask Error: ", e);
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
            payload: { taskId },
          });
          dispatch({
            type: projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS,
            payload: { value: activeTaskId === taskId ? false : true },
          });
        }
      }
    };
  }

  static updateChecklist(
    updatingChecklist: CheckList[],
    taskId: string,
    taskListType: TaskListSectionModel
  ): ThunkResult<Promise<void>> {
    console.log("👾 updateChecklist");
    return async (dispatch, getState) => {
      try {
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        FirebaseUsage.updateDoc(COLLECTIONS.TASKS, taskId, {
          checklist: updatingChecklist,
        }).then(() => {
          // TaskThunk.refreshTaskList(
          //   taskListType,
          //   getState().project.activeProject?.projectId
          // );
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
        });
      } catch (e) {
        console.error("updateChecklist Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static addEventLog(
    taskId: string,
    type: MessageType,
    taskListType: TaskListSectionModel
  ): ThunkResult<Promise<void>> {
    console.log("👾 addEventLog");
    return async (dispatch, getState) => {
      try {
        const user = getState().authorization.user;
        const activeProject = getState().project.activeProject;
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.addEventLog(
          activeProject!.projectId,
          taskId,
          type,
          user!
        ).then(() => {
          TaskThunk.refreshTaskList(taskListType, activeProject!.projectId);
          subsManager.subscribeLedgerList(taskId);
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
        });
      } catch (e) {
        console.error("addEventLog Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }

  static updateCheckListLock(
    taskId: string,
    checkListLock: boolean,
    taskListType: TaskListSectionModel
  ): ThunkResult<Promise<void>> {
    console.log("👾 updateCheckListLock");
    return async (dispatch, getState) => {
      try {
        const activeProject = getState().project.activeProject;
        const memberId =
          getState().authorization.membersMap[activeProject!.projectId]!
            .memberId;
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
          payload: { taskId },
        });
        TaskTransactions.updateCheckListLock(
          taskId,
          memberId,
          checkListLock
        ).then(() => {
          TaskThunk.refreshTaskList(taskListType, activeProject!.projectId);
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
            payload: { taskId },
          });
        });
      } catch (e) {
        console.error("updateCheckListLock Error: ", e);
        dispatch({
          type: taskActions.ActionNames.TASK_TOOLBAR_FAIL,
          payload: { taskId },
        });
      }
    };
  }
}
