import TaskModel from "../models/responses/task.model";
import TaskStatusModel from "../models/responses/task-status.model";
import {Timestamp} from "firebase/firestore";
import moment from "moment";

export function taskNarrative(task: TaskModel) {
    const formatTime = (time: Timestamp) => {
        if (new Date().getTime() - time.toDate().getTime() < (86400000 * 7)) {
            // if less than one week return the day as a string
            return moment(time.toDate()).format('dddd');
        } else {
            return moment(time.toDate()).format('Do MMMM YYYY');
        }
    }

    switch (task.status) {
        case TaskStatusModel.COMPLETE:
            return `Completed on ${formatTime(task.act_end_date || task.declaredCompleteTimestamp!)}`;
        case TaskStatusModel.DECLARED_COMPLETE:
            if (task.declaredCompleteTimestamp) {
                return `Declared complete on ${formatTime(task.declaredCompleteTimestamp)}`;
            }
        case TaskStatusModel.IN_PROGRESS:
        case TaskStatusModel.SUSPENDED:
            return `Started on ${formatTime(task.act_start_date!)}`;
        case TaskStatusModel.NOT_STARTED:
            return;
        default:
            return;
    }
}