import React, {useCallback, useEffect, useState} from "react";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {TaskPredecessors} from "./TaskPredecessors";
import {TaskSuccessors} from "./TaskSuccessors";
import {TaskListSection} from "./TaskListSection/TaskListSection";
import {TaskListSectionModel} from "../../../../../models/task-list-section.model";
import {TaskListHeadlineModel} from "../../../../../models/task-list-headline.model";
import InfiniteScroll from "react-infinite-scroller";
import {useDispatch} from "react-redux";
import * as taskActions from "../../../../../store/actions/task.actions";
import {Loader} from "semantic-ui-react";
import {
  useActiveProjectCalendarsSelector,
  useActiveProjectSelector, useCpmMapSelector,
  useProjectViewSelector, useViewWidthSelector
} from "../../../../../store/selectors/project.selectors";
import {TaskType} from "../../../../../models/task-type";
import {
  useIncludeMilestonesSelector,
  useSearchParamatersSelector
} from "../../../../../store/selectors/search.selectors";
import moment from "moment";
import TaskModel from "../../../../../models/responses/task.model";
import GoogleMapVisGL from "../../../components/GoogleMap/GoogleMapVisGL";
import filterTaskList from "../../../../../utils/task-filtering";
import TaskStatusModel from "../../../../../models/responses/task-status.model";
import {convertIndexToSeconds} from "../../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import {useActiveTaskSelector, useAllTaskListSelector} from "../../../../../store/selectors/task/task.selectors";

export const TaskList = () => {
  const [view, setView] = useState<"future" | "past">("future");
  const [sortCriteria, setSortCriteria] = useState<"index" | "deadline" | "target">("index");
  const activeProject = useActiveProjectSelector();
  const dispatch = useDispatch();
  const projectView = useProjectViewSelector();
  const projectCalendars = useActiveProjectCalendarsSelector();

  let tasksNotMilestonesCount = 0;
  let now = new Date();
  now.setMinutes(0, 0, 0);
  const today = moment(new Date())
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toDate();

  const loading = useTypedSelector((state) => state.task.taskListLoading);
  const moreTasks = useTypedSelector((state) => state.task.moreTasks);
  const searchParams = useSearchParamatersSelector();
  const allTasks = useAllTaskListSelector();

  const [filteredConfirmedTaskList, setFilteredConfirmedTaskList] = useState<TaskModel[]>([]);
  const [filteredDeclaredCompleteList, setFilteredDeclaredCompleteList] = useState<TaskModel[]>([]);
  const [filteredInProgressList, setFilteredInProgressList] = useState<TaskModel[]>([]);
  const [filteredQueuedList, setFilteredQueuedList] = useState<TaskModel[]>([]);
  const [filteredPendingTaskList, setFilteredPendingTaskList] = useState<TaskModel[]>([]);
  const [inProgressOpen, setInProgressOpen] = useState(true);
  const [queuedOpen, setQueuedOpen] = useState(true);
  const [declaredCompleteOpen, setDeclaredCompleteOpen] = useState(false);
  const [confirmedCompleteOpen, setConfirmedCompleteOpen] = useState(false);
  const [pendingOpen, setPendingOpen] = useState(true);
  const activeTask = useActiveTaskSelector();
  const includesMilestones = useIncludeMilestonesSelector();
  const viewWidth = useViewWidthSelector();
  // const [taskListsLoaded, setTaskListsLoaded] = useState(false);

  const cpmMap = useCpmMapSelector();
  const calendarsMap = useActiveProjectCalendarsSelector();


  const [activeTaskPage, setActiveTaskPage] = useState<any | null>(null);
  const [activeHeadline, setActiveHeadline] = useState<any | null>(null);
  const [goToTask, setGoToTask] = useState<boolean>(false);

  const navigateToTask = (task: TaskModel) => {
    // let taskIndex = 1;
    // let taskHeadline = TaskListHeadlineModel.CONFIRMED_COMPLETE;
    // let page: number = 1;
    // if (task.taskListType === TaskListSectionModel.PENDING) {
    //   taskHeadline = TaskListHeadlineModel.PENDING;
    //   setPendingOpen(true);
    //     filteredPendingTaskList.forEach((t, index) => {
    //         if (t.task_id === task.task_id) {
    //         taskIndex = index + 1;
    //         }
    //     });
    // } else if (task.status === TaskStatusModel.COMPLETE) {
    //   taskHeadline = TaskListHeadlineModel.CONFIRMED_COMPLETE;
    //   setConfirmedCompleteOpen(true);
    //   filteredConfirmedTaskList.forEach((t, index) => {
    //     if (t.task_id === task.task_id) {
    //       taskIndex = index + 1;
    //     }
    //   });
    //   page = Math.ceil(taskIndex / 10);
    // } else if (task.status === TaskStatusModel.DECLARED_COMPLETE) {
    //     taskHeadline = TaskListHeadlineModel.DECLARED_COMPLETE;
    //     setDeclaredCompleteOpen(true);
    //     filteredDeclaredCompleteList.forEach((t, index) => {
    //         if (t.task_id === task.task_id) {
    //         taskIndex = index + 1;
    //         }
    //     });
    //   page = Math.ceil(taskIndex / 10);
    // } else if (task.status === TaskStatusModel.IN_PROGRESS || task.flow) {
    //     taskHeadline = TaskListHeadlineModel.WORK_IN_PROCESS;
    //     setInProgressOpen(true);
    //     filteredInProgressList.forEach((t, index) => {
    //       if (t.task_id === task.task_id) {
    //         taskIndex = index + 1;
    //       }
    //     });
    //   page = Math.ceil(taskIndex / 10);
    // } else {
    //     filteredQueuedList.forEach((t, index) => {
    //       taskHeadline = TaskListHeadlineModel.QUEUED;
    //       setQueuedOpen(true);
    //       if (t.task_id === task.task_id) {
    //         taskIndex = index + 1;
    //       }
    //     });
    //   page = Math.ceil(taskIndex / 10);
    // }
    // console.log('navigateToTask', task, taskHeadline, taskIndex, page);
    // setActiveTaskPage(page);
    // setActiveHeadline(taskHeadline);
    setGoToTask(true);
  }

  useEffect(() => {
    if (activeTask) {
      console.log('navigateToTask', activeTask.task_id);
      navigateToTask(activeTask);
    }
  }, [projectView]);

  const [confirmedTasksCount, setConfirmedTasksCount] = useState(0);
  const [declaredTasksCount, setDeclaredTasksCount] = useState(0);
  const [inProgressTasksCount, setInProgressTasksCount] = useState(0);
  const [queuedTasksCount, setQueuedTasksCount] = useState(0);

  useEffect(() => {
    let confirmedTaskList: TaskModel[] = []
    let declaredCompleteList: TaskModel[] = []
    let inProgressList: TaskModel[] = []
    let queuedList: TaskModel[] = []
    let pendingTaskList: TaskModel[] = []
    let confirmedTasks = 0;
    let declaredTasks = 0;
    let inProgressTasks = 0;
    let queuedTasks = 0;

    filterTaskList(allTasks, searchParams, cpmMap, calendarsMap).forEach((task: TaskModel) => {
      const cpmTask = cpmMap.get(task.task_id);
        if (task.status === TaskStatusModel.COMPLETE && (
            (task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC) || searchParams.includeMilestones)) {
            confirmedTaskList.push(task)
            confirmedTasks++;
        } else if (task.status === TaskStatusModel.DECLARED_COMPLETE &&
            ((task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC) || searchParams.includeMilestones)) {
            declaredCompleteList.push(task)
            declaredTasks++;
        } else if ((task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED || cpmTask?.flow) &&
            ((task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC) || searchParams.includeMilestones)) {
            inProgressList.push(task)
            inProgressTasks++;
        } else if (task.taskListType === TaskListSectionModel.PENDING &&
            ((task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC) || searchParams.includeMilestones)) {
            pendingTaskList.push(task)
        } else if ((task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC) || searchParams.includeMilestones) {
            queuedList.push(task)
            queuedTasks++;
        }
    })
    if (sortCriteria === "index") {
        inProgressList = inProgressList.sort((aX, bX) => {
          const a = cpmMap.get(aX.task_id);
          const b = cpmMap.get(bX.task_id);
          return a.index! - b.index!
        });
        queuedList = queuedList.sort((aX, bX) => {
          const a = cpmMap.get(aX.task_id);
          const b = cpmMap.get(bX.task_id);
          return a.index! - b.index!
        });
    }
    if (sortCriteria === "target") {
       inProgressList = inProgressList.sort((aX, bX) => {
         const a = cpmMap.get(aX.task_id);
         const b = cpmMap.get(bX.task_id);
         if (a.targetFinishDate) {
           if (b.targetFinishDate) {
             return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
           }
           return -1;
         }
         if (b.targetFinishDate) {
           return 1;
         }
         return convertIndexToSeconds(a.ef, a.cal_id, projectCalendars) - convertIndexToSeconds(b.ef, b.cal_id, projectCalendars);
       });
      queuedList = inProgressList.sort((aX, bX) => {
        const a = cpmMap.get(aX.task_id);
        const b = cpmMap.get(bX.task_id);
        return convertIndexToSeconds(a.ef, a.cal_id, projectCalendars) - convertIndexToSeconds(b.ef, b.cal_id, projectCalendars);
      });
    }
    if (sortCriteria === "deadline") {
      inProgressList = inProgressList.sort((aX, bX) => {
        const a = cpmMap.get(aX.task_id);
        const b = cpmMap.get(bX.task_id);
        return convertIndexToSeconds(a.lf, a.cal_id, projectCalendars) - convertIndexToSeconds(b.lf, b.cal_id, projectCalendars);
      });
      queuedList = queuedList.sort((aX, bX) => {
        const a = cpmMap.get(aX.task_id);
        const b = cpmMap.get(bX.task_id);
        return convertIndexToSeconds(a.lf, a.cal_id, projectCalendars) - convertIndexToSeconds(b.lf, b.cal_id, projectCalendars);
      });
    }

    const setActiveTaskParameters = (task: TaskModel) => {
      let taskIndex = 1;
      let taskHeadline = TaskListHeadlineModel.CONFIRMED_COMPLETE;
      let page: number = 1;
      if (task.taskListType === TaskListSectionModel.PENDING) {
        taskHeadline = TaskListHeadlineModel.PENDING;
        setPendingOpen(true);
        pendingTaskList.forEach((t, index) => {
          if (t.task_id === task.task_id) {
            taskIndex = index + 1;
          }
        });
      } else if (task.status === TaskStatusModel.COMPLETE) {
        taskHeadline = TaskListHeadlineModel.CONFIRMED_COMPLETE;
        setConfirmedCompleteOpen(true);
        confirmedTaskList.forEach((t, index) => {
          if (t.task_id === task.task_id) {
            taskIndex = index + 1;
          }
        });
        page = Math.ceil(taskIndex / 10);
      } else if (task.status === TaskStatusModel.DECLARED_COMPLETE) {
        taskHeadline = TaskListHeadlineModel.DECLARED_COMPLETE;
        setDeclaredCompleteOpen(true);
        declaredCompleteList.forEach((t, index) => {
          if (t.task_id === task.task_id) {
            taskIndex = index + 1;
          }
        });
        page = Math.ceil(taskIndex / 10);
      } else if (task.status === TaskStatusModel.IN_PROGRESS || task.flow) {
        taskHeadline = TaskListHeadlineModel.WORK_IN_PROCESS;
        setInProgressOpen(true);
        inProgressList.forEach((t, index) => {
          if (t.task_id === task.task_id) {
            taskIndex = index + 1;
          }
        });
        page = Math.ceil(taskIndex / 10);
      } else {
        queuedList.forEach((t, index) => {
          taskHeadline = TaskListHeadlineModel.QUEUED;
          setQueuedOpen(true);
          if (t.task_id === task.task_id) {
            taskIndex = index + 1;
          }
        });
        page = Math.ceil(taskIndex / 10);
      }
      // console.log('navigateToTask', task, taskHeadline, taskIndex, page);
      setActiveTaskPage(page);
      setActiveHeadline(taskHeadline);
    }

    declaredCompleteList = declaredCompleteList.sort((a, b) => b.declaredCompleteTimestamp!.seconds - a.declaredCompleteTimestamp!.seconds)
    confirmedTaskList = confirmedTaskList.sort((a, b) => b.act_end_date!.seconds - a.act_end_date!.seconds)

    if (activeTask && goToTask) {
      setActiveTaskParameters(activeTask);
    }

    setFilteredConfirmedTaskList(confirmedTaskList);
    setFilteredDeclaredCompleteList(declaredCompleteList);
    setFilteredInProgressList(inProgressList);
    setFilteredQueuedList(queuedList);
    setFilteredPendingTaskList(pendingTaskList);
    setConfirmedTasksCount(confirmedTasks);
    setDeclaredTasksCount(declaredTasks);
    setInProgressTasksCount(inProgressTasks);
    setQueuedTasksCount(queuedTasks);
  }, [searchParams, sortCriteria, allTasks, goToTask]);

  const openPredecessors = useTypedSelector(
    (state) => state.task.predecessors.open
  );
  const openSuccessors = useTypedSelector(
    (state) => state.task.successors.open
  );
  const loadMoreHandler = useCallback(
    (page: number) => {
      if (view === "future") {
        if (!loading.QueuedIsLoading && moreTasks.queued) {
          dispatch(
            taskActions.Actions.loadMoreTaskList(
              page,
              TaskListSectionModel.QUEUED
            )
          );
        }
      } else {
        if (
          !loading.ConfirmedCompleteIsLoading &&
          moreTasks.confirmedComplete
        ) {
          dispatch(
            taskActions.Actions.loadMoreTaskList(
              page,
              TaskListSectionModel.CONFIRMED_COMPLETE
            )
          );
        }
      }
    },
    [
      dispatch,
      loading.ConfirmedCompleteIsLoading,
      loading.QueuedIsLoading,
      moreTasks.confirmedComplete,
      moreTasks.queued,
      view,
    ]
  );

  if (openPredecessors) {
    return <TaskPredecessors />;
  }

  if (openSuccessors) {
    return <TaskSuccessors />;
  }

  return (
      <div style={{minWidth: "100%"}}>
        <div className="tasks-sort-header"
             style={{
               // position: "sticky",
               // top: "0",
               backgroundColor: "rgb(255 255 255 / 80%)",
               zIndex: "200"
             }}>
          <div className="priority-task">
            <div
                className={"priority " + (sortCriteria === "index" ? "active" : "")}
                onClick={() => setSortCriteria("index")}
            >
              <span className="priority-text">Priority</span>&nbsp;
            </div>
            <div className="task-desc">Deliverable</div>
          </div>
          <div className="right-filters">
            {viewWidth >= 1200 &&
                <div className="task-header narrow" style={{textAlign: "center"}}>
                  Start
                </div>}
            {viewWidth >= 1200 &&
                <div className="task-header narrow" style={{textAlign: "center"}}>
                  Finish
                </div>}
            <div style={{textAlign: "right"}}
                 className={"narrow time " + (sortCriteria === "target" ? "active" : "")}
                 onClick={() => setSortCriteria("target")}
            >
              Target
            </div>
            <div style={{textAlign: "right"}}
                 className={"task-header critical " + (sortCriteria === "deadline" ? "active" : "")}
                 onClick={() => setSortCriteria("deadline")}
            >Deadline
            </div>
          </div>
        </div>
        <div className="lazy-load-container" id="flow-container">
          {projectView === "task-map" && <GoogleMapVisGL/>}
          <InfiniteScroll
              pageStart={1}
              loadMore={loadMoreHandler}
              initialLoad={false}
              hasMore={
                view === "future"
                    ? !loading.QueuedIsLoading && moreTasks.queued
                    : !loading.ConfirmedCompleteIsLoading && moreTasks.confirmedComplete
              }
              useWindow={false}
          >
            <div className="lazy-load-content" style={{zIndex: "100"}}>
              {filteredPendingTaskList.length > 0 ? (
                  <TaskListSection
                      text="New tasks pending approval"
                      moreTasks={moreTasks.pending}
                      isLoading={loading.PendingIsLoading}
                      headline={TaskListHeadlineModel.PENDING}
                      title={
                          TaskListHeadlineModel.PENDING +
                          filteredPendingTaskList.length +
                          (confirmedTasksCount === 1 ? " task" : " tasks")
                      }
                      taskList={filteredPendingTaskList}
                      view={view}
                      setView={setView}
                      total={includesMilestones ? allTasks.length : activeProject!.tasksNotMilestonesCount}
                      quality={false}
                      type={"task"}
                      open={pendingOpen}
                      setOpen={setPendingOpen}
                      activeTaskPage={activeTaskPage}
                      activeHeadline={activeHeadline}
                      setActiveTaskPage={setActiveTaskPage}
                      setActiveHeadline={setActiveHeadline}
                      setGoToTask={setGoToTask}
                      navigateToTask={navigateToTask}
                  />
              ) : null}
              {filteredConfirmedTaskList.length > 0 ? (
                  <TaskListSection
                      text="Confirmed Complete"
                      moreTasks={moreTasks.confirmedComplete}
                      isLoading={loading.ConfirmedCompleteIsLoading}
                      headline={TaskListHeadlineModel.CONFIRMED_COMPLETE}
                      title={
                          TaskListHeadlineModel.CONFIRMED_COMPLETE +
                          " - " +
                          ((confirmedTasksCount / allTasks.length) * 100).toFixed(2) +
                          "%  - " +
                          confirmedTasksCount +
                          (confirmedTasksCount === 1 ? " task" : " tasks")
                      }
                      taskList={filteredConfirmedTaskList}
                      view={view}
                      setView={setView}
                      total={includesMilestones ? allTasks.length : activeProject!.tasksNotMilestonesCount}
                      quality={false}
                      type={"task"}
                      open={confirmedCompleteOpen}
                      setOpen={setConfirmedCompleteOpen}
                      activeTaskPage={activeTaskPage}
                      activeHeadline={activeHeadline}
                      setActiveTaskPage={setActiveTaskPage}
                      setActiveHeadline={setActiveHeadline}
                      setGoToTask={setGoToTask}
                      navigateToTask={navigateToTask}
                  />
              ) : null}
              {filteredDeclaredCompleteList.length > 0 ? (
                  <TaskListSection
                      text="Completed"
                      moreTasks={moreTasks.declaredComplete}
                      isLoading={loading.DeclaredCompleteIsLoading}
                      headline={TaskListHeadlineModel.DECLARED_COMPLETE}
                      title={
                          TaskListHeadlineModel.DECLARED_COMPLETE +
                          " - " +
                          ((declaredTasksCount / allTasks.length) * 100).toFixed(2) +
                          "%  - " +
                          declaredTasksCount +
                          (declaredTasksCount === 1 ? " task" : " tasks")
                      }
                      taskList={filteredDeclaredCompleteList}
                      view={view}
                      setView={setView}
                      total={includesMilestones ? allTasks.length : activeProject!.tasksNotMilestonesCount}
                      quality={false}
                      type={"task"}
                      open={declaredCompleteOpen}
                      setOpen={setDeclaredCompleteOpen}
                      activeTaskPage={activeTaskPage}
                      activeHeadline={activeHeadline}
                      setActiveTaskPage={setActiveTaskPage}
                      setActiveHeadline={setActiveHeadline}
                      setGoToTask={setGoToTask}
                      navigateToTask={navigateToTask}
                  />
              ) : null}
              {filteredInProgressList.length > 0 ? (
                  <TaskListSection
                      text="Countdown"
                      moreTasks={moreTasks.inProgress}
                      isLoading={loading.WorkInProgressIsLoading}
                      headline={TaskListHeadlineModel.WORK_IN_PROCESS}
                      title={
                          TaskListHeadlineModel.WORK_IN_PROCESS +
                          " - " +
                          ((inProgressTasksCount / allTasks.length) * 100).toFixed(2) +
                          "%  - " +
                          inProgressTasksCount +
                          (inProgressTasksCount === 1 ? " task" : " tasks")
                      }
                      taskList={filteredInProgressList}
                      view={view}
                      setView={setView}
                      total={includesMilestones ? allTasks.length : activeProject!.tasksNotMilestonesCount}
                      quality={false}
                      type={"task"}
                      open={inProgressOpen}
                      setOpen={setInProgressOpen}
                      activeTaskPage={activeTaskPage}
                      activeHeadline={activeHeadline}
                      setActiveTaskPage={setActiveTaskPage}
                      setActiveHeadline={setActiveHeadline}
                      setGoToTask={setGoToTask}
                      navigateToTask={navigateToTask}
                  />
              ) : null}
              {filteredQueuedList.length > 0 ? (
                  <TaskListSection
                      text="Forecast Start"
                      moreTasks={moreTasks.queued}
                      isLoading={loading.QueuedIsLoading}
                      headline={TaskListHeadlineModel.QUEUED}
                      title={
                          TaskListHeadlineModel.QUEUED + " - " +
                          ((queuedTasksCount / allTasks.length) * 100).toFixed(2) +
                          "%  - " +
                          queuedTasksCount +
                          (queuedTasksCount === 1 ? " task" : " tasks")
                      }
                      taskList={filteredQueuedList}
                      view={view}
                      setView={setView}
                      total={includesMilestones ? allTasks.length : activeProject!.tasksNotMilestonesCount}
                      quality={false}
                      type={"task"}
                      open={queuedOpen}
                      setOpen={setQueuedOpen}
                      activeTaskPage={activeTaskPage}
                      activeHeadline={activeHeadline}
                      setActiveTaskPage={setActiveTaskPage}
                      setActiveHeadline={setActiveHeadline}
                      setGoToTask={setGoToTask}
                      navigateToTask={navigateToTask}
                  />
              ) : null}
              {(view === "future"
                  ? loading.QueuedIsLoading
                  : loading.ConfirmedCompleteIsLoading) && (
                  <div className="load-more-items-wrap" key={0}>
                    <Loader active/>
                  </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
  );
};
